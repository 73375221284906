import React, { useState, useEffect } from "react";
import axios from "axios";
import { ErrorMessage, Loader } from "./AdminStructure1";
import MentorPlaceholder from "../assets/images/resource.png";

export const AllMentors = ({
  allMentorInfo,
  setAllMentorInfo,
  apiData,
  isLoading,
  errorMessage,
  mentorReviews,
  setMentorReviews,
  confirmBox,
  setConfirmBox,
  setIsLoading
}) => {
  const [selectedMentorId, setSelectedMentorId] = useState(null);

  const handleRemoveMentor = async (userId) => {
    try {
      setIsLoading(true);
      const response = await axios.post(
        "https://9jadelivery.com/femaletechMobile/public/api/rejectMentor",
        { userId }
      );
      if (response.status === 200) {
        setIsLoading(false);
        setConfirmBox(false);
        setAllMentorInfo(false);
      } else {
        console.log("Failed to admit mentor: ", response.data);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error.message);
    }
  };

  return (
    <>
      {errorMessage && (
        <ErrorMessage
          message={`Error fetching program data: ${errorMessage}`}
        />
      )}
      {isLoading ? (
        <Loader />
      ) : (
        <ul className="mentorship-page resource-page">
          {apiData &&
            apiData.length > 0 &&
            apiData.map((item, index) => (
              <Mentor
                mentor={item}
                key={index}
                allMentorInfo={allMentorInfo}
                setAllMentorInfo={setAllMentorInfo}
                mentorReviews={mentorReviews}
                setMentorReviews={setMentorReviews}
                selectedMentorId={selectedMentorId}
                setSelectedMentorId={setSelectedMentorId}
                onRemoveMentor={handleRemoveMentor}
                confirmBox={confirmBox}
                setConfirmBox={setConfirmBox}
                isLoading = {isLoading}
              />
            ))}
        </ul>
      )}
    </>
  );
};

const Mentor = ({
  mentor,
  allMentorInfo,
  setAllMentorInfo,
  selectedMentorId,
  setSelectedMentorId,
  onRemoveMentor,
  mentorReviews,
  setMentorReviews,
  confirmBox,
  setConfirmBox,
  isLoading
}) => {
  const handleLearnMoreClick = () => {
    setSelectedMentorId(mentor.id);
    setAllMentorInfo(true);
  };

  return (
    <>
      {mentor.permission && (
        <li>
          <div style={{ width: "30%", position: "static", padding: "0" }}>
            <img
              src={
                mentor.picture.includes("images")
                  ? mentor.picture
                  : MentorPlaceholder
              }
              style={{ width: "100%" }}
              alt="Mentor Cover"
              className="mindfulness-cover"
            />
          </div>
          <div
            style={{
              width: "80%",
            }}
          >
            <h3
              style={{
                fontSize: "1.4rem",
                lineHeight: "1.3em",
                fontWeight: "700",
                marginBottom: "0.5rem",
              }}
            >
              {mentor.fullName}
            </h3>
            <p>
              {`Profession: `}
              <span
                style={{
                  fontWeight: "700",
                  color: "#000000",
                }}
              >
                {mentor.jobRole}
              </span>
            </p>
            <p>
              {`Experience: `}
              <span
                style={{
                  fontWeight: "700",
                  color: "#000000",
                }}
              >
                {mentor.levelOfExperience}
              </span>
            </p>
            <p>
              {`Current role: `}
              <span
                style={{
                  fontWeight: "700",
                  color: "#000000",
                }}
              >
                {mentor.jobRole}
              </span>
            </p>

            <button
              style={{
                background: "none",
                color: "#c30532",
                fontWeight: "700",
                textTransform: "capitalize",
                fontSize: "1.1rem",
                letterSpacing: "0",
                marginTop: "0.3rem",
                display: "flex",
                alignItems: "center",
              }}
              onClick={handleLearnMoreClick}
            >
              <span>Learn More</span>
              <span
                style={{
                  marginLeft: "0.3rem",
                  marginTop: "0.3rem",
                }}
              >
                &gt;
              </span>
            </button>
          </div>
        </li>
      )}
      {allMentorInfo && selectedMentorId === mentor.id && (
        <MentorInformation
          mentor={mentor}
          setAllMentorInfo={setAllMentorInfo}
          setMentorReviews={setMentorReviews}
          setConfirmBox={setConfirmBox}
          setSelectedMentorId={setSelectedMentorId}
        />
      )}

      {mentorReviews && selectedMentorId === mentor.id && (
        <Reviews
          mentor={mentor}
          setMentorReviews={setMentorReviews}
          setAllMentorInfo={setAllMentorInfo}
        />
      )}

      {confirmBox && selectedMentorId === mentor.id && (
        <DeleteConfirmation
          mentor={mentor}
          setConfirmBox={setConfirmBox}
          setAllMentorInfo={setAllMentorInfo}
          onRemoveMentor={onRemoveMentor}
          isLoading = {isLoading}
        />
      )}
    </>
  );
};

const MentorInformation = ({
  mentor,
  setAllMentorInfo,
  setMentorReviews,
  setConfirmBox,
  setSelectedMentorId,
}) => {
  const handleMentorReviewsClick = () => {
    setSelectedMentorId(mentor.id);
    setAllMentorInfo(false);
    setMentorReviews(true);
  };
  const handleMentorRemovalClick = () => {
    setSelectedMentorId(mentor.id);
    setAllMentorInfo(false);
    setConfirmBox(true);
  };
  return (
    <div
      style={{
        position: "absolute",
        width: "43rem",
        height: "45rem",
        borderRadius: "12px",
        background: "#ffffff",
        overflow: "auto",
        fontSize: "1.3rem",
        left: "53rem",
        zIndex: "999",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "1.6rem 2rem 1.4rem 2rem",
          borderBottom: "1px solid #D7D7D7",
        }}
      >
        <p
          style={{
            fontSize: "1.7rem",
            fontWeight: "600",
            color: "#212529",
          }}
        >
          Suspend Mentor
        </p>
        <ToggleSwitch mentor={mentor} />
      </div>
      <div
        style={{
          padding: "1.3rem 2rem 2.8rem 2rem",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "1rem",
            marginBottom: "1.4rem",
          }}
        >
          <div style={{ width: "30%", position: "static", padding: "0" }}>
            <img
              src={
                mentor.picture.includes("images")
                  ? mentor.picture
                  : MentorPlaceholder
              }
              style={{ width: "100%" }}
              alt="Mentor Cover"
              className="mindfulness-cover"
            />
          </div>
          <div
            style={{
              width: "80%",
            }}
          >
            <h3
              style={{
                fontSize: "1.4rem",
                lineHeight: "1.3em",
                fontWeight: "700",
                marginBottom: "0.8rem",
              }}
            >
              {mentor.fullName}
            </h3>
            <p
              style={{
                marginBottom: "0.5rem",
              }}
            >
              {`Profession: `}
              <span
                style={{
                  fontWeight: "700",
                }}
              >
                {mentor.jobRole}
              </span>
            </p>
            <p
              style={{
                marginBottom: "0.5rem",
              }}
            >
              {`Experience: `}
              <span
                style={{
                  fontWeight: "700",
                }}
              >
                {mentor.levelOfExperience}
              </span>
            </p>

            <p
              style={{
                marginBottom: "1.2rem",
              }}
            >
              {`Current role: `}
              <span
                style={{
                  fontWeight: "700",
                }}
              >
                {mentor.jobRole}
              </span>
            </p>
            <button
              style={{
                background: "none",
                border: "none",
                padding: "0",
                color: "#c30532",
                fontWeight: "700",
                textTransform: "capitalize",
                fontSize: "1.3rem",
                letterSpacing: "0",
                marginTop: "0.8rem",
                display: "flex",
                alignItems: "center",
              }}
              onClick={handleMentorReviewsClick}
            >
              <span>Reviews</span>
              <span
                style={{
                  marginLeft: "0.4rem",
                  marginTop: "0.1rem",
                }}
              >
                &gt;
              </span>
            </button>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            backgroundColor: "#c30532",
            borderRadius: "6px",
            color: "#ffffff",
            padding: "1.5rem 1rem",
            marginBottom: "2rem",
          }}
        >
          <p>
            Total Session Completed:{" "}
            <span
              style={{
                fontWeight: "800",
              }}
            >
              {mentor.total_no_session}
            </span>
          </p>
          <p>
            Total Mentoring Time:{" "}
            <span
              style={{
                fontWeight: "800",
              }}
            >
              {mentor.total_incoming_time}
            </span>
          </p>
        </div>
        <div>
          <p style={{ fontWeight: "700" }}>Bio:</p>
          <p
            style={{
              lineHeight: "1.6em",
              padding: "0.5rem 4rem 0 0",
              color: "#828282",
            }}
          >
            {mentor.aboutMe}
          </p>
        </div>
        <p
          style={{
            margin: "1.2rem 0 1.5rem 0",
            fontWeight: "700",
          }}
        >{`LinkedIn: ${mentor.linkedInProfile}`}</p>
        <div>
          Availability Date:
          <p style={{ marginTop: "0.5rem" }}>
            {mentor.available_session
              ? mentor.available_session
              : "No available date"}
          </p>
        </div>
        <div
          style={{
            marginTop: "2.6rem",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <button
            style={{
              marginLeft: "0",
              marginRight: "2rem",
              width: "46%",
              fontWeight: "700",
              textTransform: "none",
              fontSize: "1.2rem",
            }}
            className="btn2Style"
            onClick={handleMentorRemovalClick}
          >
            Remove Mentor
          </button>

          <button
            style={{
              width: "46%",
              fontWeight: "700",
              background: "none",
              color: "#c3053c",
              textTransform: "none",
              fontSize: "1.2rem",
            }}
            onClick={() => setAllMentorInfo(false)}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

const ToggleSwitch = ({ mentor }) => {
  const [isOn, setIsOn] = useState(false);
  const [toggleIsLoading, setToggleIsLoading] = useState(false);

  useEffect(() => {
    if (mentor.suspended === "0") {
      setIsOn(false);
    } else {
      setIsOn(true);
    }
  }, [mentor.suspended]);

  const handleToggle = async (userId) => {
    if (!isOn) {
      try {
        setToggleIsLoading(true);
        const response = await axios.post(
          "https://9jadelivery.com/femaletechMobile/public/api/suspendMentor",
          { userId }
        );

        if (response.data && response.data.code === 200) {
          setIsOn(true);
          setToggleIsLoading(false);
        } else {
          console.log("Failed to suspend mentor: ", response.data);
          setToggleIsLoading(false);
        }
      } catch (error) {
        console.log(error.message);
        setToggleIsLoading(false);
      }
    } else {
      try {
        const response = await axios.post(
          "https://9jadelivery.com/femaletechMobile/public/api/liftSuspensionOnMentor",
          { userId }
        );

        if (response.data && response.data.code === 200) {
          setIsOn(false);
        } else {
          console.log("Failed to suspend mentor: ", response.data);
        }
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  return (
    <div
      className="toggle-switch"
      onClick={() => !toggleIsLoading && handleToggle(mentor.userId)}
      style={{ cursor: toggleIsLoading ? "not-allowed" : "pointer" }}
    >
      <div className={`slider ${isOn ? "suspend-mentor" : ""}`}></div>
    </div>
  );
};

const Reviews = ({ mentor, setMentorReviews, setAllMentorInfo }) => {
  const handleMentorReviewsCancel = () => {
    setAllMentorInfo(true);
    setMentorReviews(false);
  };
  return (
    <div
      style={{
        position: "absolute",
        width: "43rem",
        height: "45rem",
        borderRadius: "12px",
        background: "#ffffff",
        overflow: "auto",
        fontSize: "1.3rem",
        left: "53rem",
        zIndex: "999",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "1.6rem 2rem 1.4rem 2rem",
          borderBottom: "1px solid #D7D7D7",
        }}
      >
        <p
          style={{
            fontSize: "1.7rem",
            fontWeight: "600",
            color: "#212529",
          }}
        >
          Reviews
        </p>
        <button
          style={{
            background: "none",
            border: "none",
            padding: "0",
          }}
          onClick={handleMentorReviewsCancel}
        >
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.25 0.75L0.75 11.25M0.75 0.75L11.25 11.25"
              stroke="#C30532"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
      </div>
      <div
        style={{
          padding: "1.3rem 2rem 2.8rem 2rem",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "1rem",
            marginBottom: "1.4rem",
          }}
        >
          <div style={{ width: "18%", position: "static", padding: "0" }}>
            <img
              src={
                mentor.picture.includes("images")
                  ? mentor.picture
                  : MentorPlaceholder
              }
              style={{ width: "100%", borderRadius: "4px" }}
              alt="Mentor Cover"
              className="mindfulness-cover"
            />
          </div>
          <div
            style={{
              width: "80%",
            }}
          >
            <h3
              style={{
                fontSize: "1.5rem",
                lineHeight: "1.3em",
                fontWeight: "700",
                marginBottom: "0.8rem",
                color: "#212529",
              }}
            >
              {mentor.fullName}
            </h3>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                backgroundColor: "#c30532",
                borderRadius: "6px",
                color: "#ffffff",
                padding: "1.2rem 1rem",
              }}
            >
              <p>
                Amount Earned:{" "}
                <span
                  style={{
                    fontWeight: "800",
                  }}
                >
                  {mentor.amount_earned}
                </span>
              </p>
              <p>
                Premium Session:{" "}
                <span
                  style={{
                    fontWeight: "800",
                  }}
                >
                  {mentor.premium_session}
                </span>
              </p>
            </div>
          </div>
        </div>
        <div>
          <h5
            style={{
              fontWeight: "600",
              fontSize: "1.4rem",
              margin: "3rem 0 1rem 0",
            }}
          >
            Rating
          </h5>
          <p
            style={{
              fontWeight: "700",
              color: "#212529",
              fontSize: "1.8rem",
              display: "flex",
              alignItems: "center",
            }}
          >
            {mentor.rating}
            <span style={{ marginLeft: "7px" }}>
              <Rating rating={mentor.rating} />
            </span>
          </p>
          <Mentee menteeData={mentor.mentee} />
        </div>
      </div>
    </div>
  );
};

const Rating = ({ totalStars = 5, rating = 0, size = "15" }) => {
  return (
    <div style={{ display: "flex" }}>
      {[...Array(totalStars)].map((_, index) => {
        const starIndex = index + 1;
        return (
          <Star
            key={starIndex}
            starIndex={starIndex}
            rating={rating}
            size={size}
          />
        );
      })}
    </div>
  );
};

const Star = ({ starIndex, rating, size }) => {
  const fill = rating >= starIndex ? "#C30532" : "#FFFFFF";

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={fill}
      stroke="#C30532"
      strokeWidth="2"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        marginRight: "5px",
      }}
    >
      <path d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.849 1.477 8.332L12 18.902l-7.413 4.585L6.064 15.155.5 9.306l8.332-1.151L12 .587z" />
    </svg>
  );
};

const Mentee = (menteeData) => {
  return (
    <ul
      style={{
        display: "block",
      }}
    >
      {menteeData &&
        menteeData.menteeData.length > 0 &&
        menteeData.menteeData.map((item, index) => (
          <MenteeItem mentee={item} key={index} />
        ))}
    </ul>
  );
};

const MenteeItem = ({ mentee }) => {
  return (
    <li
      style={{
        display: "flex",
        alignItems: "flex-start",
        border: "none",
        margin: "4rem 0",
        padding: "0",
      }}
    >
      <div
        style={{
          width: "25%",
          position: "static",
          padding: "0",
        }}
      >
        <img
          src={
            mentee.picture.includes("images")
              ? mentee.picture
              : MentorPlaceholder
          }
          style={{ width: "100%" }}
          alt="Mentee"
        />
      </div>
      <div>
        <h6
          style={{
            fontSize: "1.4rem",
            color: "#212529",
          }}
        >
          {mentee.fullName}
        </h6>
        <div
          style={{
            margin: "0.7rem 0",
          }}
        >
          {" "}
          <Rating
            rating={mentee.rating[mentee.rating.length - 1]?.rating}
            size="12"
          />
        </div>
        <p
          style={{
            fontSize: "1.2rem",
            color: "#828282",
          }}
        >
          {mentee.aboutMe
            ? mentee.aboutMe
            : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."}
        </p>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: "1rem",
            fontWeight: "600",
            color: "#212529",
          }}
        >
          <p
            style={{
              fontSize: "1.3rem",
            }}
          >
            {mentee.session_date}
          </p>
          {/* <span
            style={{
              fontSize: "0.3rem",
              margin: "0 1rem",
            }}
          >
            ⚫
          </span>
          <div
            style={{
              display: "flex",
              alignItems:"center"
            }}
          >
            <svg
              width="15"
              height="16"
              viewBox="0 0 16 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16 8.3999C16 3.98177 12.4181 0.399902 8 0.399902C3.58187 0.399902 0 3.98177 0 8.3999C0 12.818 3.58187 16.3999 8 16.3999C12.4181 16.3999 16 12.818 16 8.3999Z"
                fill="#C30532"
              />
              <path
                d="M4.44434 7.95523L7.111 10.6219L11.5552 6.17773"
                fill="#C30532"
              />
              <path
                d="M4.44434 7.95523L7.111 10.6219L11.5552 6.17773"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <p style={{
              marginLeft: "0.3rem",
              marginBottom: "0",
              }}>Premium Session | </p>
          </div> */}
        </div>
      </div>
    </li>
  );
};

const DeleteConfirmation = ({
  mentor,
  setAllMentorInfo,
  setConfirmBox,
  onRemoveMentor,
  isLoading
}) => {
  const handleConfirmationCancel = () => {
    setAllMentorInfo(true);
    setConfirmBox(false);
  };
  return (
    <div
      style={{
        position: "absolute",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        width: "43rem",
        height: "20rem",
        borderRadius: "12px",
        background: "#ffffff",
        overflow: "auto",
        fontSize: "1.3rem",
        left: "53rem",
        pointerEvents: "all",
        zIndex: 999,
      }}
    >
      <p
        style={{
          fontSize: "3rem",
          textAlign: "center",
        }}
      >
        Are you sure you want to remove mentor?
      </p>
      <div
        style={{
          margin: "2.6rem 0",
          display: "flex",
          gap: "2rem",
          justifyContent: "space-between",
        }}
      >
        <button
          style={{
            width: "46%",
            marginLeft: "2rem",
            fontWeight: "700",
            background: "none",
            color: "#c3053c",
            textTransform: "none",
            fontSize: "1.2rem",
          }}
          onClick={handleConfirmationCancel}
        >
          Cancel
        </button>

        <button
          style={{
            marginLeft: "0",
            marginRight: "2rem",
            width: "46%",
            fontWeight: "700",
            textTransform: "none",
            fontSize: "1.2rem",
             cursor: isLoading ? "not-allowed" : "pointer"
          }}
          className="btn2Style"
          onClick={() => onRemoveMentor(mentor.userId)}
        >
          Remove Mentor
        </button>
      </div>
    </div>
  );
};

export default AllMentors;
