import React, { useState, useEffect } from "react";
import axios from "axios";
import FormSuccessfulMessage from "./FormSuccessfulMessage";
import DatePicker from "./DatePicker1";
import Timepicker from "./Timepicker1";

const AdminEventForm = ({
  setMenu2Active,
  itemToEdit,
  editMode,
  isLoading,
  setIsLoading,
}) => {
  const [eventFormData, setEventFormData] = useState({
    id: null,
    eventPicture: null,
    cta: null,
    eventName: "",
    eventDescription: "",
    date: "",
    time: "",
    duration: "",
    location: "",
    currency: "",
    amountOfEvent: "",
  });

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [inputError, setInputError] = useState(false);
  const [imageToggle, setImageToggle] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (editMode) {
      setEventFormData((prevFormData) => ({
        ...prevFormData,
        id: itemToEdit.id,
        eventPicture: itemToEdit.eventPicture,
        eventName: itemToEdit.eventName,
        eventDescription: itemToEdit.eventDescription,
        date: itemToEdit.date,
        time: itemToEdit.time,
        duration: itemToEdit.duration,
        location: itemToEdit.location,
        currency: itemToEdit.currency,
        amountOfEvent: itemToEdit.amountOfEvent,
        cta: itemToEdit.cta,
      }));

      setImageToggle(true);
    }
  }, [editMode, itemToEdit]);

  const handleInputChange = ({ target }) => {
    const { name, value } = target;

    setEventFormData({
      ...eventFormData,
      [name]: value,
    });
  };

  const getImage = (e) => {
    const fileData = e.target.files[0];

    setEventFormData({
      ...eventFormData,
      eventPicture: fileData,
    });
  };

  const postEvent = async (eventData) => {
    try {
      setIsLoading(true);
      const response = await axios.post(
        "https://9jadelivery.com/femaletechMobile/public/api/addEvent",
        eventData
      );
      if (response.data && response.data.code === 200) {
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error posting program:", error.message);
      throw error;
    }
  };

  const editEvent = async (eventData) => {
    try {
      setIsLoading(true);
      const response = await axios.post(
        `https://9jadelivery.com/femaletechMobile/public/api/editEvent`,
        eventData
      );
      if (response.data && response.data.code === 200) {
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error posting program:", error.message);
      throw error;
    }
  };

  const addEvent = async (payload) => {
    const formData = new FormData();
    Object.entries(payload).forEach(([key, value]) => {
      formData.append(key, value);
    });

    try {
      editMode ? await editEvent(formData) : await postEvent(formData);
      setFormSubmitted(true);
    } catch (err) {
      setErrorMessage(err.message);
      setIsLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Check for empty fields
    const isAnyFieldEmpty = Object.entries(eventFormData).some(
      ([key, value]) => {
        if (key === "picture" || key === "cta") {
          return value === null;
        }
        return value === "";
      }
    );

    if (isAnyFieldEmpty) {
      setInputError(true);

      // Clear the error after 2 seconds
      setTimeout(() => {
        setInputError(false);
      }, 2000);

      return;
    }

    addEvent(eventFormData);

    // console.log(eventFormData);
  };

  const handleFormHomeBtn = () => {
    setMenu2Active(1);
  };

  return (
    <div className="event-form programme-form">
      {errorMessage ? (
        <ErrorMessage message={`Error submitting form : ${errorMessage}`} />
      ) : (
        <form onSubmit={isLoading ? (e) => e.preventDefault() : handleSubmit}>
          {!formSubmitted ? (
            <div>
              <label>
                Title:
                <input
                  type="text"
                  name="eventName"
                  value={eventFormData.eventName}
                  onChange={handleInputChange}
                  placeholder="Event title"
                />
              </label>
              <br />
              <div>
                Date/Time:
                <div className="date-time">
                  <label
                    style={{
                      display: "flex",
                      margin: 0,
                    }}
                  >
                    <DatePicker
                      selectedDate={eventFormData.date}
                      onDateChange={(date) =>
                        handleInputChange({
                          target: { name: "date", value: date },
                        })
                      }
                    />
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="calendar-icon"
                    >
                      <rect
                        x="2"
                        y="3"
                        width="20"
                        height="19"
                        rx="3"
                        stroke="#6C757D"
                        stroke-width="2"
                      />
                      <path
                        d="M7 1V3"
                        stroke="#6C757D"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M17 1V3"
                        stroke="#6C757D"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M2 8H22"
                        stroke="#6C757D"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M6.5 13H7.5"
                        stroke="#6C757D"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M11.5 13H12.5"
                        stroke="#6C757D"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M16.5 13H17.5"
                        stroke="#6C757D"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M6.5 17H7.5"
                        stroke="#6C757D"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M11.5 17H12.5"
                        stroke="#6C757D"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M16.5 17H17.5"
                        stroke="#6C757D"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </label>
                  <label
                    style={{
                      display: "flex",
                      margin: 0,
                    }}
                  >
                    <Timepicker
                      selectedTime={eventFormData.time}
                      onTimeChange={(time) =>
                        handleInputChange({
                          target: { name: "time", value: time },
                        })
                      }
                    />
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="time-icon"
                    >
                      <circle
                        cx="12"
                        cy="12"
                        r="11"
                        stroke="#6C757D"
                        stroke-width="2"
                      />
                      <path
                        d="M12 7V13L14.5 15.5"
                        stroke="#6C757D"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </label>
                </div>
              </div>
              <br />
              <label>
                Description:
                <textarea
                  id="description"
                  name="eventDescription"
                  rows="4"
                  cols="50"
                  value={eventFormData.eventDescription}
                  onChange={handleInputChange}
                  placeholder="Enter a description"
                ></textarea>
              </label>
              <br />
              <label>
                Duration:
                <input
                  type="text"
                  name="duration"
                  value={eventFormData.duration}
                  onChange={handleInputChange}
                  placeholder="Nill"
                />
              </label>
              <br />
              <label>
                Event location:
                <input
                  type="text"
                  name="location"
                  value={eventFormData.location}
                  onChange={handleInputChange}
                  placeholder="Nill"
                />
              </label>
              <br />
              <label>
                Currency:
                <input
                  type="text"
                  name="currency"
                  value={eventFormData.currency}
                  onChange={handleInputChange}
                  placeholder="Nill"
                />
              </label>
              <br />
              <label>
                Input ticket price:
                <input
                  type="number"
                  name="amountOfEvent"
                  value={eventFormData.amountOfEvent}
                  onChange={handleInputChange}
                  placeholder="0"
                />
              </label>
              <br />
              <label>
                Call To Action:
                <input
                  type="text"
                  name="cta"
                  value={eventFormData.cta}
                  onChange={handleInputChange}
                  placeholder="Nill"
                />
              </label>
              <br />
              {editMode && imageToggle && (
                <div>
                  <img
                    className="form-flyer"
                    src={eventFormData.eventPicture}
                    alt="Event Flyer"
                  />
                  <p
                    className="remove-form-flyer"
                    onClick={() => setImageToggle(false)}
                  >
                    Remove Image
                  </p>
                </div>
              )}
              {!imageToggle && (
                <label>
                  Upload picture:
                  <input
                    type="file"
                    id="image"
                    accept=".png, .jpg, .jpeg"
                    onChange={(e) => getImage(e)}
                  />
                </label>
              )}

              <br />
              <button
                type="submit"
                style={{ cursor: isLoading ? "not-allowed" : "pointer" }}
                className="continue btn2Style"
              >
                {editMode ? "Update event" : "List event"}
              </button>
              {inputError && (
                <p className="input-error">⛔ Please fill in all fields</p>
              )}
            </div>
          ) : (
            <FormSuccessfulMessage
              text={
                editMode
                  ? "Event updated successfully"
                  : "Event listed successfully"
              }
              onclick={handleFormHomeBtn}
            />
          )}
        </form>
      )}
    </div>
  );
};

const ErrorMessage = ({ message }) => {
  return (
    <p className="error">
      <span>⛔️</span> {message}
    </p>
  );
};

export default AdminEventForm;
