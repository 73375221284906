import React, { useState } from "react";
import axios from "axios";
import { ErrorMessage, Loader } from "./AdminStructure1";
import MentorPlaceholder from "../assets/images/resource.png";

export const MentorApplication = ({
  allMentorInfo,
  setAllMentorInfo,
  apiData,
  isLoading,
  setIsLoading,
  errorMessage,
}) => {

  const [selectedMentorId, setSelectedMentorId] = useState(null);

  const handleAcceptMentor = (userId) => {
    const acceptMentor = async () => {
      try {
        setIsLoading(true);
        const response = await axios.post(
          "https://9jadelivery.com/femaletechMobile/public/api/admitMentor",
          { userId }
        );
        console.log(response.data);
        if (response.status === 200) {
          setIsLoading(false);
          setAllMentorInfo(false);
        } else {
          console.log("Failed to admit mentor: ", response.data);
        }
      } catch (error) {
        setIsLoading(false);
        console.log(error.message);
      }
    };

    acceptMentor();
  };

  const handleRejectMentor = async (userId) => {
    try {
      setIsLoading(true);
      const response = await axios.post(
        "https://9jadelivery.com/femaletechMobile/public/api/rejectMentor",
        { userId }
      );
      console.log(response.data);
      if (response.status === 200) {
        setIsLoading(false);
        setAllMentorInfo(false); 
      } else {
        console.log("Failed to reject mentor: ", response.data);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error.message);
    }
  };
  

  return (
    <>
      {errorMessage && (
        <ErrorMessage
          message={`Error fetching program data: ${errorMessage}`}
        />
      )}
      {isLoading ? (
        <Loader />
      ) : (
        <ul className="mentorship-page resource-page">
          {apiData &&
            apiData.length > 0 &&
            apiData.map((item, index) => (
              <MentorshipRequest
                mentorRequest={item}
                key={index}
                allMentorInfo={allMentorInfo}
                setAllMentorInfo={setAllMentorInfo}
                selectedMentorId={selectedMentorId}
                setSelectedMentorId={setSelectedMentorId}
                onAcceptMentor={handleAcceptMentor}
                onRejectMentor={handleRejectMentor}
                isLoading = {isLoading}
              />
            ))}
        </ul>
      )}
    </>
  );
};

const MentorshipRequest = ({
  mentorRequest,
  allMentorInfo,
  setAllMentorInfo,
  selectedMentorId,
  setSelectedMentorId,
  onAcceptMentor,
  onRejectMentor,
  isLoading
}) => {
  const handleLearnMoreClick = () => {
    setSelectedMentorId(mentorRequest.id);
    setAllMentorInfo(true);
  };

  return (
    <>
      {mentorRequest.permission === "false" ? (
        <li>
          <div style={{ width: "30%", position: "static", padding: "0" }}>
            <img
              src={
                mentorRequest.picture.includes("images")
                  ? mentorRequest.picture
                  : MentorPlaceholder
              }
              style={{ width: "100%" }}
              alt="Applicant Cover"
              className="mindfulness-cover"
            />
          </div>
          <div
            style={{
              width: "80%",
            }}
          >
            <h3
              style={{
                fontSize: "1.4rem",
                lineHeight: "1.3em",
                fontWeight: "600",
                marginBottom: "0.5rem",
              }}
            >
              {mentorRequest.fullName}
            </h3>
            <p>
              {`Profession: `}
              <span
                style={{
                  fontWeight: "700",
                  color: "#000000",
                }}
              >
                {mentorRequest.jobRole}
              </span>
            </p>
            <p>
              {`Experience: `}
              <span
                style={{
                  fontWeight: "700",
                  color: "#000000",
                }}
              >
                {mentorRequest.levelOfExperience}
              </span>
            </p>
            <p>
              {`Current role: `}
              <span
                style={{
                  fontWeight: "700",
                  color: "#000000",
                }}
              >
                {mentorRequest.jobRole}
              </span>
            </p>

            <button
              style={{
                background: "none",
                color: "#c30532",
                fontWeight: "700",
                textTransform: "capitalize",
                fontSize: "1.1rem",
                letterSpacing: "0",
                marginTop: "0.3rem",
                display: "flex",
                alignItems: "center",
              }}
              onClick={handleLearnMoreClick}
            >
              <span>Learn More</span>
              <span
                style={{
                  marginLeft: "0.3rem",
                  marginTop: "0.3rem",
                }}
              >
                &gt;
              </span>
            </button>
          </div>
        </li>
      ) : (
        ""
      )}

      {allMentorInfo && selectedMentorId === mentorRequest.id && (
        <MentorInformation
          mentorRequest={mentorRequest}
          setAllMentorInfo={setAllMentorInfo}
          onAcceptMentor={onAcceptMentor}
          onRejectMentor={onRejectMentor}
          isLoading = {isLoading}
        />
      )}
    </>
  );
};

const MentorInformation = ({
  mentorRequest,
  setAllMentorInfo,
  onAcceptMentor,
  onRejectMentor,
  isLoading
}) => {
  return (
    <div
      style={{
        position: "absolute",
        width: "36rem",
        height: "45rem",
        borderRadius: "12px",
        background: "#ffffff",
        padding: "1.3rem 2rem 1.8rem 2rem",
        overflow: "auto",
        fontSize: "1.3rem",
        left: "53rem",
        zIndex: "999",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom:"1rem",
        }}
      >
        <button
          style={{
            padding: "0.7rem 1rem",
            fontSize: "1.4rem",
            background: "none",
          }}
          onClick={() => setAllMentorInfo(false)}
        >
          X
        </button>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "1rem",
          marginBottom: "1.4rem",
        }}
      >
        <div style={{ width: "30%", position: "static", padding: "0" }}>
          <img
            src={
              mentorRequest.picture.includes("images")
                ? mentorRequest.picture
                : MentorPlaceholder
            }
            style={{ width: "100%" }}
            alt="Applicant Cover"
            className="mindfulness-cover"
          />
        </div>
        <div
          style={{
            width: "80%",
          }}
        >
          <h3
            style={{
              fontSize: "1.4rem",
              lineHeight: "1.3em",
              fontWeight: "700",
              marginBottom: "0.8rem",
            }}
          >
            {mentorRequest.fullName}
          </h3>
          <p
            style={{
              marginBottom: "0.5rem",
            }}
          >
            {`Profession: `}
            <span
              style={{
                fontWeight: "700",
              }}
            >
              {mentorRequest.jobRole}
            </span>
          </p>
          <p
            style={{
              marginBottom: "0.5rem",
            }}
          >
            {`Experience: `}
            <span
              style={{
                fontWeight: "700",
              }}
            >
              {mentorRequest.levelOfExperience}
            </span>
          </p>

          <p
            style={{
              marginBottom: "0.5rem",
            }}
          >
            {`Current role: `}
            <span
              style={{
                fontWeight: "700",
              }}
            >
              {mentorRequest.jobRole}
            </span>
          </p>
        </div>
      </div>
      <div>
        <p style={{ fontWeight: "700" }}>Bio:</p>
        <p
          style={{
            lineHeight: "1.6em",
            padding: "0.5rem 4rem 0 0",
            color: "#828282",
          }}
        >
          {mentorRequest.aboutMe}
        </p>
      </div>
      <p
        style={{
          margin: "1.2rem 0 1.5rem 0",
          fontWeight: "700",
        }}
      >{`LinkedIn: ${mentorRequest.linkedInProfile}`}</p>
      <div>
        Availability Date:
        <p style={{ marginTop: "0.5rem" }}>
          {mentorRequest.available_session
            ? mentorRequest.available_session
            : "No available date"}
        </p>
      </div>
      <div
        style={{
          marginTop: "2.3rem",
        }}
      >
        <button
          style={{
            marginLeft: "0",
            marginRight: "2rem",
            width: "46%",
            fontWeight: "700",
            cursor: isLoading ? "not-allowed" : "pointer"
          }}
          className="btn2Style"
          onClick={() => onAcceptMentor(mentorRequest.userId)}
        >
          Accept
        </button>
        <button
          style={{
            width: "46%",
            fontWeight: "700",
            cursor: isLoading ? "not-allowed" : "pointer"
          }}
          className="btn1Style"
          onClick={() => onRejectMentor(mentorRequest.userId)}
        >
          Reject
        </button>
      </div>
    </div>
  );
};

export default MentorApplication;
