import { useState, useEffect } from "react";
import Sidebar from "../Sidebar.js";
import AdminStructure1, { Loader, ErrorMessage } from "../AdminStructure1.js";
import AdminNavbar from "../AdminNavbar.js";
import AdminMenu2 from "../AdminMenu2.js";
import "../../styles/admin.css";
import AdminProgrammeForm from "../AdminProgrammeForm.js";
import AdminEventForm from "../AdminEventForm.js";
import AdminChatSupport from "../AdminChatSupport.js";
import axios from "axios";
import AdminResources from "../AdminResources.js";
import AdminResourcesAndMindfulnessForm from "../AdminResourcesAndMindfulnessForm.js";
import AdminMindfulness from "../AdminMindfulness.js";
import PastEvents from "../PastEvents.js";
import AdminPodcast from "../AdminPodcast.js";
import AdminPodcastForm from "../AdminPodcastForm.js";
import AdminSpotlight from "../AdminSpotlight.js";
import AdminSpotlightForm from "../AdminSpotlightForm.js";
import AdminSubscriptionPlanForm from "../AdminSubscriptionPlanForm.js";
import withAuth from "../withAuth.js";
import FundHerForm from "../FundHerForm.js";
import MentorApplication from "../MentorApplication.js";
import AllMentors from "../AllMentors.js";
import UpcomingMentorSession from "../UpcomingMentorSession.js";
import AddPastEventPicturesForm from "../AddPastEventPicturesForm.js";
import WithdrawalRequest from "../WithdrawalRequest.js";
import MoveToPastEventsConfirmation from "../MoveToPastEventsConfirmation.js";
import WithdrawalDetails from "../WithdrawalDetails.js";
import ServicePackagesForm from "../ServicePackagesForm.js";
import DeleteConfirmation from "../DeleteConfirmation.js";

const AdminDashboard = () => {
  const [isActive, setIsActive] = useState(1);
  const [isOverlay, setIsOverlay] = useState(false);

  return (
    <div className="admin">
      <Sidebar isActive={isActive} setIsActive={setIsActive} />
      <MainContent
        isActive={isActive}
        key={isActive}
        isOverlay={isOverlay}
        setIsOverlay={setIsOverlay}
      />
      {isOverlay && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100dvh",
            zIndex: 99,
            background: "#83838340",
            backdropFilter: "blur(1px)",
          }}
        ></div>
      )}
    </div>
  );
};

const MainContent = ({ isActive, setIsOverlay }) => {
  const [menu2Active, setMenu2Active] = useState(1);
  const [apiData, setApiData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [menuLength, setMenuLength] = useState(0);
  const [itemToEdit, setItemToEdit] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [articleLink, setArticleLink] = useState("");
  const [filePreview, setFilePreview] = useState(false);
  const [coverImagePreview, setcoverImagePreview] = useState(false);
  const [allMentorInfo, setAllMentorInfo] = useState(false);
  const [mentorReviews, setMentorReviews] = useState(false);
  const [confirmBox, setConfirmBox] = useState(false);
  const [confirmBox2, setConfirmBox2] = useState(false);
  const [createFundHer, setCreateFundHer] = useState(false);
  const [isPastEventPictures, setIsPastEventPictures] = useState(false);
  const [eventID, setEventID] = useState("");
  const [currentItem, setCurrentItem] = useState("");

  useEffect(() => {
    if (isActive === 9) {
      if (menu2Active === 1) {
        fetchMentors(
          "https://9jadelivery.com/femaletechMobile/public/api/mentorRequest"
        );
      } else if (menu2Active === 2) {
        fetchMentors(
          "https://9jadelivery.com/femaletechMobile/public/api/allMentor"
        );
      } else if (menu2Active === 3) {
        fetchMentors(
          "https://9jadelivery.com/femaletechMobile/public/api/allBookedSessions"
        );
      }
    }
  }, [isActive, menu2Active]);

  const fetchMentors = async (url) => {
    try {
      setIsLoading(true);
      const response = await axios.post(url);
      setApiData(response.data);
      setIsLoading(false);
    } catch (error) {
      setErrorMessage(error.message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (allMentorInfo || mentorReviews || confirmBox || confirmBox2) {
      setIsOverlay(true);
    } else {
      setIsOverlay(false);
      if (isActive === 9) {
        if (menu2Active === 1) {
          fetchMentors(
            "https://9jadelivery.com/femaletechMobile/public/api/mentorRequest"
          );
        } else if (menu2Active === 2) {
          fetchMentors(
            "https://9jadelivery.com/femaletechMobile/public/api/allMentor"
          );
        }
      }
    }
  }, [
    allMentorInfo,
    mentorReviews,
    menu2Active,
    isActive,
    confirmBox,
    confirmBox2,
    setIsOverlay,
  ]);

  const handleEdit = (id, api) => {
    const fetch = async () => {
      try {
        const response = await axios.post(api, { id: id });

        if (response.data) {
          setItemToEdit(response.data);
          setEditMode(true);
          if (isActive === 1 && menu2Active === 1) {
            setMenu2Active(2);
          } else if (isActive === 2 && menu2Active === 2) {
            setCreateFundHer(true);
          } else if (isActive === 1 && menu2Active === 3) {
            setMentorReviews(false);
            setIsPastEventPictures(true);
            setConfirmBox(true);
          } else {
            setMenu2Active(menuLength);
          }
        }
      } catch (error) {
        console.error("Error fetching program:", error);
      }
    };

    fetch();
  };

  return (
    <div className="main-content">
      <AdminNavbar />
      {isActive === 1 && (
        <AdminMenu2
          menu2Active={menu2Active}
          setMenu2Active={setMenu2Active}
          menu={[
            {
              id: 1,
              icon: (
                <svg
                  width="18"
                  height="14"
                  viewBox="0 0 20 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.25 0C1.00736 0 0 1.00736 0 2.25V4.75C0 5.99264 1.00736 7 2.25 7H4.75C5.99264 7 7 5.99264 7 4.75V2.25C7 1.00736 5.99264 0 4.75 0H2.25ZM1.5 2.25C1.5 1.83579 1.83579 1.5 2.25 1.5H4.75C5.16421 1.5 5.5 1.83579 5.5 2.25V4.75C5.5 5.16421 5.16421 5.5 4.75 5.5H2.25C1.83579 5.5 1.5 5.16421 1.5 4.75V2.25ZM9.25 1C8.8358 1 8.5 1.33579 8.5 1.75C8.5 2.16421 8.8358 2.5 9.25 2.5H19.25C19.6642 2.5 20 2.16421 20 1.75C20 1.33579 19.6642 1 19.25 1H9.25ZM9.25 4C8.8358 4 8.5 4.33579 8.5 4.75C8.5 5.16421 8.8358 5.5 9.25 5.5H16.25C16.6642 5.5 17 5.16421 17 4.75C17 4.33579 16.6642 4 16.25 4H9.25ZM2.25 9C1.00736 9 0 10.0074 0 11.25V13.75C0 14.9926 1.00736 16 2.25 16H4.75C5.99264 16 7 14.9926 7 13.75V11.25C7 10.0074 5.99264 9 4.75 9H2.25ZM1.5 11.25C1.5 10.8358 1.83579 10.5 2.25 10.5H4.75C5.16421 10.5 5.5 10.8358 5.5 11.25V13.75C5.5 14.1642 5.16421 14.5 4.75 14.5H2.25C1.83579 14.5 1.5 14.1642 1.5 13.75V11.25ZM9.25 10C8.8358 10 8.5 10.3358 8.5 10.75C8.5 11.1642 8.8358 11.5 9.25 11.5H19.25C19.6642 11.5 20 11.1642 20 10.75C20 10.3358 19.6642 10 19.25 10H9.25ZM9.25 13C8.8358 13 8.5 13.3358 8.5 13.75C8.5 14.1642 8.8358 14.5 9.25 14.5H16.25C16.6642 14.5 17 14.1642 17 13.75C17 13.3358 16.6642 13 16.25 13H9.25Z"
                    fill={menu2Active === 1 ? "#C30532" : "#474747"}
                  />
                </svg>
              ),
              title: "Listed event",
            },

            {
              id: 2,
              icon: (
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.7498 0C9.1295 0 9.4434 0.28201 9.4931 0.64808L9.5 0.74985L9.5012 8H16.7543C17.1685 8 17.5043 8.3358 17.5043 8.75C17.5043 9.1297 17.2221 9.4435 16.8561 9.4932L16.7543 9.5H9.5012L9.5032 16.7491C9.5033 17.1633 9.1676 17.4993 8.7534 17.4993C8.3737 17.4993 8.0598 17.2173 8.0101 16.8512L8.0032 16.7494L8.0012 9.5H0.752197C0.337977 9.5 0.00219727 9.1642 0.00219727 8.75C0.00219727 8.3703 0.284347 8.0565 0.650427 8.0068L0.752197 8H8.0012L8 0.75015C7.9999 0.33594 8.3356 0 8.7498 0Z"
                    fill={menu2Active === 2 ? "#C30532" : "#474747"}
                  />
                </svg>
              ),
              title: "List new event",
            },
            {
              id: 3,
              title: "Past events",
            },
          ]}
          setMenuLength={setMenuLength}
          setEditMode={setEditMode}
          setFilePreview={setFilePreview}
          setCreateFundHer={setCreateFundHer}
        >
          {menu2Active === 1 && (
            <AdminStructure1
              api="https://9jadelivery.com/femaletechMobile/public/api/getEvent"
              setApiData={setApiData}
              setIsLoading={setIsLoading}
              setErrorMessage={setErrorMessage}
            >
              <table>
                <thead>
                  <tr>
                    <th>S/N</th>
                    <th
                      style={{
                        width: "50%",
                      }}
                    >
                      Name/About
                    </th>
                    <th></th>
                    <th colSpan="2">Ticket Price</th>
                    <th></th>
                    <th colSpan="2">Ticket Sold</th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th
                      style={{
                        width: "20%",
                      }}
                    ></th>
                  </tr>
                </thead>
                <tbody>
                  {errorMessage && (
                    <ErrorMessage
                      message={`Error fetching program data : ${errorMessage}`}
                    />
                  )}
                  {isLoading ? (
                    <Loader />
                  ) : (
                    apiData.present?.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td className="content">
                          <div>
                            <img src={item.eventPicture} alt="" />
                          </div>
                          <div
                            style={{
                              width: "190%",
                            }}
                          >
                            <h3>{item.eventName}</h3>
                            <p>{item.eventDescription}</p>
                          </div>
                        </td>
                        <td></td>
                        <td>
                          {item.currency}
                          {item.amountOfEvent}
                        </td>
                        <td></td>
                        <td></td>
                        <td>{item.totalSold}</td>
                        <td
                          style={{
                            padding: "1rem",
                          }}
                        ></td>
                        <td></td>
                        <td>
                          {item.userId === "null" || item.userId === null ? (
                            <button
                              onClick={() =>
                                handleEdit(
                                  item.id,
                                  "https://9jadelivery.com/femaletechMobile/public/api/getEvent"
                                )
                              }
                            >
                              <svg
                                width="20"
                                height="21"
                                viewBox="0 0 20 21"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M18.9519 1.0481C17.5543 -0.349421 15.2885 -0.349361 13.8911 1.04825L1.94103 12.9997C1.5347 13.4061 1.2491 13.9172 1.116 14.4762L0.0204088 19.0777C-0.0399112 19.3311 0.0355188 19.5976 0.219679 19.7817C0.403849 19.9659 0.670369 20.0413 0.923729 19.981L5.52498 18.8855C6.08418 18.7523 6.59546 18.4666 7.00191 18.0601L8.1531 16.9088C8.0528 16.4552 8 15.9838 8 15.5C8 15.3026 8.0088 15.1073 8.026 14.9144L5.94119 16.9995C5.73104 17.2097 5.46668 17.3574 5.17755 17.4263L1.76191 18.2395L2.57521 14.8237C2.64402 14.5346 2.79168 14.2704 3.00175 14.0603L13 4.06084L15.9394 7.00018L13.9137 9.0261C14.1068 9.0088 14.3024 9 14.5 9C14.9835 9 15.4547 9.0528 15.9081 9.1529L18.952 6.10861C20.3493 4.71112 20.3493 2.4455 18.9519 1.0481ZM14.9518 2.10884C15.7634 1.29709 17.0795 1.29705 17.8912 2.10876C18.7028 2.9204 18.7029 4.23632 17.8913 5.04801L17 5.93946L14.0606 3.00012L14.9518 2.10884ZM20 15.5C20 18.5376 17.5376 21 14.5 21C11.4624 21 9 18.5376 9 15.5C9 12.4624 11.4624 10 14.5 10C17.5376 10 20 12.4624 20 15.5ZM10.5 15.5C10.5 16.3335 10.755 17.1075 11.1911 17.7482L16.7482 12.1911C16.1075 11.755 15.3335 11.5 14.5 11.5C12.2909 11.5 10.5 13.2909 10.5 15.5ZM14.5 19.5C16.7091 19.5 18.5 17.7091 18.5 15.5C18.5 14.6665 18.245 13.8925 17.8089 13.2518L12.2518 18.8089C12.8925 19.245 13.6665 19.5 14.5 19.5Z"
                                  fill="#C30532"
                                />
                              </svg>
                              <p>Edit</p>
                            </button>
                          ) : (
                            ""
                          )}
                        </td>
                        <td
                          style={{
                            padding: "0 0.5rem",
                          }}
                        ></td>
                        <td>
                          {item.userId === "null" || item.userId === null ? (
                            <button
                              onClick={() => {
                                setConfirmBox2(true);
                                setEventID(item.id);
                              }}
                            >
                              <svg
                                width="20"
                                height="21"
                                viewBox="0 0 20 21"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M11.7999 3.80078C11.7999 2.80667 10.994 2.00078 9.9999 2.00078C9.00579 2.00078 8.1999 2.80667 8.1999 3.80078H11.7999ZM6.9999 3.80078C6.9999 2.14393 8.34305 0.800781 9.9999 0.800781C11.6567 0.800781 12.9999 2.14393 12.9999 3.80078H18.9999C19.3312 3.80078 19.5999 4.06941 19.5999 4.40078C19.5999 4.73215 19.3312 5.00078 18.9999 5.00078H17.7346L16.7654 13.4008H15.5575L16.5267 5.00078H3.47311L4.88947 17.2758C5.0292 18.4869 6.0546 19.4008 7.27366 19.4008H10.1024C10.036 19.5885 9.9999 19.7904 9.9999 20.0008C9.9999 20.2111 10.036 20.4131 10.1024 20.6008H7.27366C5.44507 20.6008 3.90697 19.2299 3.69738 17.4135L2.26515 5.00078H0.999902C0.668534 5.00078 0.399902 4.73215 0.399902 4.40078C0.399902 4.06941 0.668534 3.80078 0.999902 3.80078H6.9999ZM11.7999 14.6008C11.4686 14.6008 11.1999 14.8695 11.1999 15.2008C11.1999 15.5321 11.4686 15.8008 11.7999 15.8008H18.9999C19.3312 15.8008 19.5999 15.5321 19.5999 15.2008C19.5999 14.8695 19.3312 14.6008 18.9999 14.6008H11.7999ZM11.7999 17.0008C11.4686 17.0008 11.1999 17.2695 11.1999 17.6008C11.1999 17.9321 11.4686 18.2008 11.7999 18.2008H18.9999C19.3312 18.2008 19.5999 17.9321 19.5999 17.6008C19.5999 17.2695 19.3312 17.0008 18.9999 17.0008H11.7999ZM11.7999 19.4008C11.4686 19.4008 11.1999 19.6695 11.1999 20.0008C11.1999 20.3321 11.4686 20.6008 11.7999 20.6008H18.9999C19.3312 20.6008 19.5999 20.3321 19.5999 20.0008C19.5999 19.6695 19.3312 19.4008 18.9999 19.4008H11.7999Z"
                                  fill="#C30532"
                                />
                              </svg>
                              <p>Remove</p>
                            </button>
                          ) : (
                            ""
                          )}
                        </td>
                        <td
                          style={{
                            padding: "0 0.5rem",
                          }}
                        ></td>
                        <td
                          style={{
                            padding: "1.3rem 0 0 0",
                          }}
                        >
                          {item.userId === "null" || item.userId === null ? (
                            <button
                              className="btn2Style"
                              onClick={() => {
                                setConfirmBox(true);
                                setEventID(item.id);
                              }}
                              style={{
                                textTransform: "none",
                                fontWeight: "600",
                                fontFamily: "Lato, sans-serif",
                                fontSize: "1.2rem",
                                padding: "1rem 2rem",
                              }}
                            >
                              Mark as Done
                            </button>
                          ) : (
                            ""
                          )}
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
              {confirmBox2 && (
                <DeleteConfirmation
                  setConfirmBox2={setConfirmBox2}
                  eventID={eventID}
                  api={
                    "https://9jadelivery.com/femaletechMobile/public/api/deleteEvent"
                  }
                  setEditMode={setEditMode}
                  isActive={isActive}
                  menu2Active={menu2Active}
                  setMenu2Active={setMenu2Active}
                  menuLength={menuLength}
                />
              )}
              {confirmBox && (
                <MoveToPastEventsConfirmation
                  setConfirmBox={setConfirmBox}
                  setIsPastEventPictures={setIsPastEventPictures}
                />
              )}
              {isPastEventPictures && (
                <AddPastEventPicturesForm
                  setConfirmBox={setConfirmBox}
                  setIsPastEventPictures={setIsPastEventPictures}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  eventID={eventID}
                  setMenu2Active={setMenu2Active}
                  editMode={editMode}
                  setEditMode={setEditMode}
                  itemToEdit={itemToEdit}
                />
              )}
            </AdminStructure1>
          )}
          {menu2Active === 2 && (
            <AdminEventForm
              setMenu2Active={setMenu2Active}
              itemToEdit={itemToEdit}
              editMode={editMode}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
          )}
          {menu2Active === 3 && (
            <PastEvents
              setItemToEdit={setItemToEdit}
              setEditMode={setEditMode}
              setMenu2Active={setMenu2Active}
              menuLength={menuLength}
              mentorReviews={mentorReviews}
              setMentorReviews={setMentorReviews}
              onEdit={handleEdit}
              isPastEventPictures={isPastEventPictures}
              setConfirmBox={setConfirmBox}
              setIsPastEventPictures={setIsPastEventPictures}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              eventID={eventID}
              editMode={editMode}
              itemToEdit={itemToEdit}
            />
          )}
        </AdminMenu2>
      )}
      {isActive === 2 && (
        <AdminMenu2
          menu2Active={menu2Active}
          setMenu2Active={setMenu2Active}
          menu={[
            {
              id: 1,
              icon: (
                <svg
                  width="18"
                  height="14"
                  viewBox="0 0 20 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.25 0C1.00736 0 0 1.00736 0 2.25V4.75C0 5.99264 1.00736 7 2.25 7H4.75C5.99264 7 7 5.99264 7 4.75V2.25C7 1.00736 5.99264 0 4.75 0H2.25ZM1.5 2.25C1.5 1.83579 1.83579 1.5 2.25 1.5H4.75C5.16421 1.5 5.5 1.83579 5.5 2.25V4.75C5.5 5.16421 5.16421 5.5 4.75 5.5H2.25C1.83579 5.5 1.5 5.16421 1.5 4.75V2.25ZM9.25 1C8.8358 1 8.5 1.33579 8.5 1.75C8.5 2.16421 8.8358 2.5 9.25 2.5H19.25C19.6642 2.5 20 2.16421 20 1.75C20 1.33579 19.6642 1 19.25 1H9.25ZM9.25 4C8.8358 4 8.5 4.33579 8.5 4.75C8.5 5.16421 8.8358 5.5 9.25 5.5H16.25C16.6642 5.5 17 5.16421 17 4.75C17 4.33579 16.6642 4 16.25 4H9.25ZM2.25 9C1.00736 9 0 10.0074 0 11.25V13.75C0 14.9926 1.00736 16 2.25 16H4.75C5.99264 16 7 14.9926 7 13.75V11.25C7 10.0074 5.99264 9 4.75 9H2.25ZM1.5 11.25C1.5 10.8358 1.83579 10.5 2.25 10.5H4.75C5.16421 10.5 5.5 10.8358 5.5 11.25V13.75C5.5 14.1642 5.16421 14.5 4.75 14.5H2.25C1.83579 14.5 1.5 14.1642 1.5 13.75V11.25ZM9.25 10C8.8358 10 8.5 10.3358 8.5 10.75C8.5 11.1642 8.8358 11.5 9.25 11.5H19.25C19.6642 11.5 20 11.1642 20 10.75C20 10.3358 19.6642 10 19.25 10H9.25ZM9.25 13C8.8358 13 8.5 13.3358 8.5 13.75C8.5 14.1642 8.8358 14.5 9.25 14.5H16.25C16.6642 14.5 17 14.1642 17 13.75C17 13.3358 16.6642 13 16.25 13H9.25Z"
                    fill={menu2Active === 1 ? "#C30532" : "#474747"}
                  />
                </svg>
              ),
              title: "Listed general programme",
            },
            {
              id: 2,
              icon: (
                <svg
                  width="18"
                  height="14"
                  viewBox="0 0 20 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.25 0C1.00736 0 0 1.00736 0 2.25V4.75C0 5.99264 1.00736 7 2.25 7H4.75C5.99264 7 7 5.99264 7 4.75V2.25C7 1.00736 5.99264 0 4.75 0H2.25ZM1.5 2.25C1.5 1.83579 1.83579 1.5 2.25 1.5H4.75C5.16421 1.5 5.5 1.83579 5.5 2.25V4.75C5.5 5.16421 5.16421 5.5 4.75 5.5H2.25C1.83579 5.5 1.5 5.16421 1.5 4.75V2.25ZM9.25 1C8.8358 1 8.5 1.33579 8.5 1.75C8.5 2.16421 8.8358 2.5 9.25 2.5H19.25C19.6642 2.5 20 2.16421 20 1.75C20 1.33579 19.6642 1 19.25 1H9.25ZM9.25 4C8.8358 4 8.5 4.33579 8.5 4.75C8.5 5.16421 8.8358 5.5 9.25 5.5H16.25C16.6642 5.5 17 5.16421 17 4.75C17 4.33579 16.6642 4 16.25 4H9.25ZM2.25 9C1.00736 9 0 10.0074 0 11.25V13.75C0 14.9926 1.00736 16 2.25 16H4.75C5.99264 16 7 14.9926 7 13.75V11.25C7 10.0074 5.99264 9 4.75 9H2.25ZM1.5 11.25C1.5 10.8358 1.83579 10.5 2.25 10.5H4.75C5.16421 10.5 5.5 10.8358 5.5 11.25V13.75C5.5 14.1642 5.16421 14.5 4.75 14.5H2.25C1.83579 14.5 1.5 14.1642 1.5 13.75V11.25ZM9.25 10C8.8358 10 8.5 10.3358 8.5 10.75C8.5 11.1642 8.8358 11.5 9.25 11.5H19.25C19.6642 11.5 20 11.1642 20 10.75C20 10.3358 19.6642 10 19.25 10H9.25ZM9.25 13C8.8358 13 8.5 13.3358 8.5 13.75C8.5 14.1642 8.8358 14.5 9.25 14.5H16.25C16.6642 14.5 17 14.1642 17 13.75C17 13.3358 16.6642 13 16.25 13H9.25Z"
                    fill={menu2Active === 2 ? "#C30532" : "#474747"}
                  />
                </svg>
              ),
              title: "Listed FundHer Forward form questions",
            },
            // {
            //   id: 3,
            //   icon: (
            //     <svg
            //       width="16"
            //       height="16"
            //       viewBox="0 0 18 18"
            //       fill="none"
            //       xmlns="http://www.w3.org/2000/svg"
            //     >
            //       <path
            //         d="M8.7498 0C9.1295 0 9.4434 0.28201 9.4931 0.64808L9.5 0.74985L9.5012 8H16.7543C17.1685 8 17.5043 8.3358 17.5043 8.75C17.5043 9.1297 17.2221 9.4435 16.8561 9.4932L16.7543 9.5H9.5012L9.5032 16.7491C9.5033 17.1633 9.1676 17.4993 8.7534 17.4993C8.3737 17.4993 8.0598 17.2173 8.0101 16.8512L8.0032 16.7494L8.0012 9.5H0.752197C0.337977 9.5 0.00219727 9.1642 0.00219727 8.75C0.00219727 8.3703 0.284347 8.0565 0.650427 8.0068L0.752197 8H8.0012L8 0.75015C7.9999 0.33594 8.3356 0 8.7498 0Z"
            //         fill={menu2Active === 3 ? "#C30532" : "#474747"}
            //       />
            //     </svg>
            //   ),
            //   title: "Create new FundHer Forward form",
            // },
            {
              id: 3,
              icon: (
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.7498 0C9.1295 0 9.4434 0.28201 9.4931 0.64808L9.5 0.74985L9.5012 8H16.7543C17.1685 8 17.5043 8.3358 17.5043 8.75C17.5043 9.1297 17.2221 9.4435 16.8561 9.4932L16.7543 9.5H9.5012L9.5032 16.7491C9.5033 17.1633 9.1676 17.4993 8.7534 17.4993C8.3737 17.4993 8.0598 17.2173 8.0101 16.8512L8.0032 16.7494L8.0012 9.5H0.752197C0.337977 9.5 0.00219727 9.1642 0.00219727 8.75C0.00219727 8.3703 0.284347 8.0565 0.650427 8.0068L0.752197 8H8.0012L8 0.75015C7.9999 0.33594 8.3356 0 8.7498 0Z"
                    fill={menu2Active === 3 ? "#C30532" : "#474747"}
                  />
                </svg>
              ),
              title: "List new general programme",
            },
          ]}
          setMenuLength={setMenuLength}
          setEditMode={setEditMode}
          setFilePreview={setFilePreview}
          setCreateFundHer={setCreateFundHer}
        >
          {menu2Active === 1 && (
            <AdminStructure1
              api="https://9jadelivery.com/femaletechMobile/public/api/webPrograms"
              setApiData={setApiData}
              setIsLoading={setIsLoading}
              setErrorMessage={setErrorMessage}
            >
              <table>
                <thead>
                  <tr>
                    <th>S/N</th>
                    <th>Name/About</th>
                    <th></th>
                    <th colSpan="2">Listed sub-program</th>
                    <th></th>
                    <th colSpan="2">Program fee</th>
                    <th></th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {errorMessage && (
                    <ErrorMessage
                      message={`Error fetching program data : ${errorMessage}`}
                    />
                  )}
                  {isLoading ? (
                    <Loader />
                  ) : (
                    apiData.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td className="content programs-content">
                          <div>
                            <img src={item.picture} alt="" />
                          </div>
                          <div
                            style={{
                              width: "100%",
                            }}
                          >
                            <h3>{item.title}</h3>
                            <p>{item.description}</p>
                          </div>
                        </td>
                        <td></td>
                        <td>6</td>
                        <td></td>
                        <td></td>
                        <td>
                          {item.currency}
                          {item.amount}
                        </td>
                        <td></td>
                        <td></td>
                        <td>
                          <button
                            onClick={() =>
                              handleEdit(
                                item.id,
                                "https://9jadelivery.com/femaletechMobile/public/api/webPrograms"
                              )
                            }
                          >
                            <svg
                              width="20"
                              height="21"
                              viewBox="0 0 20 21"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M18.9519 1.0481C17.5543 -0.349421 15.2885 -0.349361 13.8911 1.04825L1.94103 12.9997C1.5347 13.4061 1.2491 13.9172 1.116 14.4762L0.0204088 19.0777C-0.0399112 19.3311 0.0355188 19.5976 0.219679 19.7817C0.403849 19.9659 0.670369 20.0413 0.923729 19.981L5.52498 18.8855C6.08418 18.7523 6.59546 18.4666 7.00191 18.0601L8.1531 16.9088C8.0528 16.4552 8 15.9838 8 15.5C8 15.3026 8.0088 15.1073 8.026 14.9144L5.94119 16.9995C5.73104 17.2097 5.46668 17.3574 5.17755 17.4263L1.76191 18.2395L2.57521 14.8237C2.64402 14.5346 2.79168 14.2704 3.00175 14.0603L13 4.06084L15.9394 7.00018L13.9137 9.0261C14.1068 9.0088 14.3024 9 14.5 9C14.9835 9 15.4547 9.0528 15.9081 9.1529L18.952 6.10861C20.3493 4.71112 20.3493 2.4455 18.9519 1.0481ZM14.9518 2.10884C15.7634 1.29709 17.0795 1.29705 17.8912 2.10876C18.7028 2.9204 18.7029 4.23632 17.8913 5.04801L17 5.93946L14.0606 3.00012L14.9518 2.10884ZM20 15.5C20 18.5376 17.5376 21 14.5 21C11.4624 21 9 18.5376 9 15.5C9 12.4624 11.4624 10 14.5 10C17.5376 10 20 12.4624 20 15.5ZM10.5 15.5C10.5 16.3335 10.755 17.1075 11.1911 17.7482L16.7482 12.1911C16.1075 11.755 15.3335 11.5 14.5 11.5C12.2909 11.5 10.5 13.2909 10.5 15.5ZM14.5 19.5C16.7091 19.5 18.5 17.7091 18.5 15.5C18.5 14.6665 18.245 13.8925 17.8089 13.2518L12.2518 18.8089C12.8925 19.245 13.6665 19.5 14.5 19.5Z"
                                fill="#C30532"
                              />
                            </svg>
                            <p>Edit</p>
                          </button>
                        </td>
                        <td></td>
                        <td>
                          <button
                            onClick={() => {
                              setConfirmBox2(true);
                              setEventID(item.id);
                            }}
                          >
                            <svg
                              width="20"
                              height="21"
                              viewBox="0 0 20 21"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M11.7999 3.80078C11.7999 2.80667 10.994 2.00078 9.9999 2.00078C9.00579 2.00078 8.1999 2.80667 8.1999 3.80078H11.7999ZM6.9999 3.80078C6.9999 2.14393 8.34305 0.800781 9.9999 0.800781C11.6567 0.800781 12.9999 2.14393 12.9999 3.80078H18.9999C19.3312 3.80078 19.5999 4.06941 19.5999 4.40078C19.5999 4.73215 19.3312 5.00078 18.9999 5.00078H17.7346L16.7654 13.4008H15.5575L16.5267 5.00078H3.47311L4.88947 17.2758C5.0292 18.4869 6.0546 19.4008 7.27366 19.4008H10.1024C10.036 19.5885 9.9999 19.7904 9.9999 20.0008C9.9999 20.2111 10.036 20.4131 10.1024 20.6008H7.27366C5.44507 20.6008 3.90697 19.2299 3.69738 17.4135L2.26515 5.00078H0.999902C0.668534 5.00078 0.399902 4.73215 0.399902 4.40078C0.399902 4.06941 0.668534 3.80078 0.999902 3.80078H6.9999ZM11.7999 14.6008C11.4686 14.6008 11.1999 14.8695 11.1999 15.2008C11.1999 15.5321 11.4686 15.8008 11.7999 15.8008H18.9999C19.3312 15.8008 19.5999 15.5321 19.5999 15.2008C19.5999 14.8695 19.3312 14.6008 18.9999 14.6008H11.7999ZM11.7999 17.0008C11.4686 17.0008 11.1999 17.2695 11.1999 17.6008C11.1999 17.9321 11.4686 18.2008 11.7999 18.2008H18.9999C19.3312 18.2008 19.5999 17.9321 19.5999 17.6008C19.5999 17.2695 19.3312 17.0008 18.9999 17.0008H11.7999ZM11.7999 19.4008C11.4686 19.4008 11.1999 19.6695 11.1999 20.0008C11.1999 20.3321 11.4686 20.6008 11.7999 20.6008H18.9999C19.3312 20.6008 19.5999 20.3321 19.5999 20.0008C19.5999 19.6695 19.3312 19.4008 18.9999 19.4008H11.7999Z"
                                fill="#C30532"
                              />
                            </svg>
                            <p>Remove</p>
                          </button>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
              {confirmBox2 && (
                <DeleteConfirmation
                  setConfirmBox2={setConfirmBox2}
                  eventID={eventID}
                  api={
                    "https://9jadelivery.com/femaletechMobile/public/api/deletePrograms"
                  }
                  setEditMode={setEditMode}
                  isActive={isActive}
                  menu2Active={menu2Active}
                  setMenu2Active={setMenu2Active}
                  menuLength={menuLength}
                />
              )}
            </AdminStructure1>
          )}
          {menu2Active === 2 && (
            <AdminStructure1
              api="https://9jadelivery.com/femaletechMobile/public/api/getFundHer"
              setApiData={setApiData}
              setIsLoading={setIsLoading}
              setErrorMessage={setErrorMessage}
            >
              <div style={{ height: "50rem", overflow: "auto" }}>
                {!createFundHer && (
                  <button
                    style={{
                      display: "flex",
                      alignItems: "center",
                      margin: "3rem 0 1rem 1.7rem",
                      textTransform: "none",
                    }}
                    className="continue btn2Style"
                    onClick={() => setCreateFundHer(true)}
                  >
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.7498 0C9.1295 0 9.4434 0.28201 9.4931 0.64808L9.5 0.74985L9.5012 8H16.7543C17.1685 8 17.5043 8.3358 17.5043 8.75C17.5043 9.1297 17.2221 9.4435 16.8561 9.4932L16.7543 9.5H9.5012L9.5032 16.7491C9.5033 17.1633 9.1676 17.4993 8.7534 17.4993C8.3737 17.4993 8.0598 17.2173 8.0101 16.8512L8.0032 16.7494L8.0012 9.5H0.752197C0.337977 9.5 0.00219727 9.1642 0.00219727 8.75C0.00219727 8.3703 0.284347 8.0565 0.650427 8.0068L0.752197 8H8.0012L8 0.75015C7.9999 0.33594 8.3356 0 8.7498 0Z"
                        fill={"#FFFFFF"}
                      />
                    </svg>
                    <span
                      style={{
                        marginLeft: "1rem",
                        fontWeight: "600",
                        fontFamily: "Lato, sans-serif",
                        fontSize: "1.2rem",
                      }}
                    >
                      Create new FundHer Forward form
                    </span>
                  </button>
                )}
                {createFundHer ? (
                  <FundHerForm
                    setMenu2Active={setMenu2Active}
                    itemToEdit={itemToEdit}
                    editMode={editMode}
                    setEditMode={setEditMode}
                    setCreateFundHer={setCreateFundHer}
                  />
                ) : (
                  <table className="fundHer" style={{ height: "100%" }}>
                    <thead>
                      <tr>
                        <th>S/N</th>
                        <th style={{ width: "60%" }}>QUESTIONS</th>
                        <th></th>
                        <th style={{ width: "30%" }}>OPTIONS</th>
                        <th>
                          <button
                            onClick={() =>
                              handleEdit(
                                apiData[0]?.id,
                                "https://9jadelivery.com/femaletechMobile/public/api/getFundHer"
                              )
                            }
                          >
                            <svg
                              width="20"
                              height="21"
                              viewBox="0 0 20 21"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M18.9519 1.0481C17.5543 -0.349421 15.2885 -0.349361 13.8911 1.04825L1.94103 12.9997C1.5347 13.4061 1.2491 13.9172 1.116 14.4762L0.0204088 19.0777C-0.0399112 19.3311 0.0355188 19.5976 0.219679 19.7817C0.403849 19.9659 0.670369 20.0413 0.923729 19.981L5.52498 18.8855C6.08418 18.7523 6.59546 18.4666 7.00191 18.0601L8.1531 16.9088C8.0528 16.4552 8 15.9838 8 15.5C8 15.3026 8.0088 15.1073 8.026 14.9144L5.94119 16.9995C5.73104 17.2097 5.46668 17.3574 5.17755 17.4263L1.76191 18.2395L2.57521 14.8237C2.64402 14.5346 2.79168 14.2704 3.00175 14.0603L13 4.06084L15.9394 7.00018L13.9137 9.0261C14.1068 9.0088 14.3024 9 14.5 9C14.9835 9 15.4547 9.0528 15.9081 9.1529L18.952 6.10861C20.3493 4.71112 20.3493 2.4455 18.9519 1.0481ZM14.9518 2.10884C15.7634 1.29709 17.0795 1.29705 17.8912 2.10876C18.7028 2.9204 18.7029 4.23632 17.8913 5.04801L17 5.93946L14.0606 3.00012L14.9518 2.10884ZM20 15.5C20 18.5376 17.5376 21 14.5 21C11.4624 21 9 18.5376 9 15.5C9 12.4624 11.4624 10 14.5 10C17.5376 10 20 12.4624 20 15.5ZM10.5 15.5C10.5 16.3335 10.755 17.1075 11.1911 17.7482L16.7482 12.1911C16.1075 11.755 15.3335 11.5 14.5 11.5C12.2909 11.5 10.5 13.2909 10.5 15.5ZM14.5 19.5C16.7091 19.5 18.5 17.7091 18.5 15.5C18.5 14.6665 18.245 13.8925 17.8089 13.2518L12.2518 18.8089C12.8925 19.245 13.6665 19.5 14.5 19.5Z"
                                fill="#C30532"
                              />
                            </svg>
                            <p
                              style={{
                                textTransform: "none",
                                fontSize: "1.2rem",
                                marginLeft: "0.5rem",
                              }}
                            >
                              Edit a question
                            </p>
                          </button>
                        </th>
                        <th></th>
                        <th style={{ width: "15%" }}>
                          <button
                            onClick={() =>
                              handleEdit(
                                apiData[0]?.id,
                                "https://9jadelivery.com/femaletechMobile/public/api/getFundHer"
                              )
                            }
                          >
                            <svg
                              width="20"
                              height="21"
                              viewBox="0 0 20 21"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M11.7999 3.80078C11.7999 2.80667 10.994 2.00078 9.9999 2.00078C9.00579 2.00078 8.1999 2.80667 8.1999 3.80078H11.7999ZM6.9999 3.80078C6.9999 2.14393 8.34305 0.800781 9.9999 0.800781C11.6567 0.800781 12.9999 2.14393 12.9999 3.80078H18.9999C19.3312 3.80078 19.5999 4.06941 19.5999 4.40078C19.5999 4.73215 19.3312 5.00078 18.9999 5.00078H17.7346L16.7654 13.4008H15.5575L16.5267 5.00078H3.47311L4.88947 17.2758C5.0292 18.4869 6.0546 19.4008 7.27366 19.4008H10.1024C10.036 19.5885 9.9999 19.7904 9.9999 20.0008C9.9999 20.2111 10.036 20.4131 10.1024 20.6008H7.27366C5.44507 20.6008 3.90697 19.2299 3.69738 17.4135L2.26515 5.00078H0.999902C0.668534 5.00078 0.399902 4.73215 0.399902 4.40078C0.399902 4.06941 0.668534 3.80078 0.999902 3.80078H6.9999ZM11.7999 14.6008C11.4686 14.6008 11.1999 14.8695 11.1999 15.2008C11.1999 15.5321 11.4686 15.8008 11.7999 15.8008H18.9999C19.3312 15.8008 19.5999 15.5321 19.5999 15.2008C19.5999 14.8695 19.3312 14.6008 18.9999 14.6008H11.7999ZM11.7999 17.0008C11.4686 17.0008 11.1999 17.2695 11.1999 17.6008C11.1999 17.9321 11.4686 18.2008 11.7999 18.2008H18.9999C19.3312 18.2008 19.5999 17.9321 19.5999 17.6008C19.5999 17.2695 19.3312 17.0008 18.9999 17.0008H11.7999ZM11.7999 19.4008C11.4686 19.4008 11.1999 19.6695 11.1999 20.0008C11.1999 20.3321 11.4686 20.6008 11.7999 20.6008H18.9999C19.3312 20.6008 19.5999 20.3321 19.5999 20.0008C19.5999 19.6695 19.3312 19.4008 18.9999 19.4008H11.7999Z"
                                fill="#C30532"
                              />
                            </svg>
                            <p
                              style={{
                                textTransform: "none",
                                fontSize: "1.2rem",
                                marginLeft: "0",
                              }}
                            >
                              Delete a question
                            </p>
                          </button>
                        </th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {errorMessage && (
                        <ErrorMessage
                          message={`Error fetching program data : ${errorMessage}`}
                        />
                      )}
                      {isLoading ? (
                        <Loader />
                      ) : (
                        <>
                          {apiData[0]?.data?.map((item, index) => (
                            <tr key={index}>
                              <td
                                style={{
                                  textAlign: "center",
                                  fontSize: "1.2rem",
                                }}
                              >
                                {index + 1}
                              </td>
                              <td
                                style={{
                                  textAlign: "center",
                                  fontSize: "1.3rem",
                                }}
                                className="content2"
                              >
                                <p>{item.question}</p>
                              </td>
                              <td></td>
                              <td style={{ padding: "2rem 0 1rem" }}>
                                {item.option?.map((option, optionIndex) => (
                                  <p
                                    key={optionIndex}
                                    style={{
                                      fontSize: "1.3rem",
                                      marginBottom: "1rem",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontSize: "0.4rem",
                                        position: "relative",
                                        bottom: "3px",
                                      }}
                                    >
                                      🔴
                                    </span>
                                    {` ${option}`}
                                  </p>
                                ))}
                              </td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                          ))}
                          <tr
                            style={{
                              display: "none",
                            }}
                          >
                            <td></td>
                            <td></td>
                            <td>
                              <button
                                // onClick={() =>
                                //   handleDelete(
                                //     apiData[0]?.id,
                                //     "https://9jadelivery.com/femaletechMobile/public/api/deleteFundHer"
                                //   )
                                // }
                                className="btn2Style"
                                style={{
                                  padding: "1rem 2rem",
                                  width: "14rem",
                                  margin: "1rem 0",
                                }}
                              >
                                <p
                                  style={{
                                    fontSize: "1.2rem",
                                    color: "#ffffff",
                                    marginLeft: "0",
                                  }}
                                >
                                  ✖ Delete form
                                </p>
                              </button>
                            </td>
                          </tr>
                        </>
                      )}
                    </tbody>
                  </table>
                )}
              </div>
            </AdminStructure1>
          )}
          {menu2Active === 3 && (
            <AdminProgrammeForm
              setMenu2Active={setMenu2Active}
              itemToEdit={itemToEdit}
              editMode={editMode}
              setEditMode={setEditMode}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
          )}
          {/* {menu2Active === 2 && (
            <AdminStructureLocalData
              api="http://localhost:8000/techbootcamp"
              setApiData={setApiData}
              setIsLoading={setIsLoading}
              setErrorMessage={setErrorMessage}
            >
              <table className="fundHer">
                <thead>
                  <tr>
                    <th>S/N</th>
                    <th>Name/About</th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {errorMessage ? (
                    <ErrorMessage
                      message={`Error fetching program data : ${errorMessage}`}
                    />
                  ) : (
                    ""
                  )}
                  {isLoading ? (
                    <Loader />
                  ) : (
                    apiData.map((item, index) => (
                      <tr key={index}>
                        <td>{item.id}</td>
                        <td className="content">
                          <div>
                            <img src={item.picture} alt="" />
                          </div>
                          <div>
                            <h3>{item.title}</h3>
                          </div>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                          <button>
                            <svg
                              width="20"
                              height="21"
                              viewBox="0 0 20 21"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M18.9519 1.0481C17.5543 -0.349421 15.2885 -0.349361 13.8911 1.04825L1.94103 12.9997C1.5347 13.4061 1.2491 13.9172 1.116 14.4762L0.0204088 19.0777C-0.0399112 19.3311 0.0355188 19.5976 0.219679 19.7817C0.403849 19.9659 0.670369 20.0413 0.923729 19.981L5.52498 18.8855C6.08418 18.7523 6.59546 18.4666 7.00191 18.0601L8.1531 16.9088C8.0528 16.4552 8 15.9838 8 15.5C8 15.3026 8.0088 15.1073 8.026 14.9144L5.94119 16.9995C5.73104 17.2097 5.46668 17.3574 5.17755 17.4263L1.76191 18.2395L2.57521 14.8237C2.64402 14.5346 2.79168 14.2704 3.00175 14.0603L13 4.06084L15.9394 7.00018L13.9137 9.0261C14.1068 9.0088 14.3024 9 14.5 9C14.9835 9 15.4547 9.0528 15.9081 9.1529L18.952 6.10861C20.3493 4.71112 20.3493 2.4455 18.9519 1.0481ZM14.9518 2.10884C15.7634 1.29709 17.0795 1.29705 17.8912 2.10876C18.7028 2.9204 18.7029 4.23632 17.8913 5.04801L17 5.93946L14.0606 3.00012L14.9518 2.10884ZM20 15.5C20 18.5376 17.5376 21 14.5 21C11.4624 21 9 18.5376 9 15.5C9 12.4624 11.4624 10 14.5 10C17.5376 10 20 12.4624 20 15.5ZM10.5 15.5C10.5 16.3335 10.755 17.1075 11.1911 17.7482L16.7482 12.1911C16.1075 11.755 15.3335 11.5 14.5 11.5C12.2909 11.5 10.5 13.2909 10.5 15.5ZM14.5 19.5C16.7091 19.5 18.5 17.7091 18.5 15.5C18.5 14.6665 18.245 13.8925 17.8089 13.2518L12.2518 18.8089C12.8925 19.245 13.6665 19.5 14.5 19.5Z"
                                fill="#C30532"
                              />
                            </svg>
                            <p>Edit</p>
                          </button>
                        </td>
                        <td></td>
                        <td>
                          <button>
                            <svg
                              width="20"
                              height="21"
                              viewBox="0 0 20 21"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M11.7999 3.80078C11.7999 2.80667 10.994 2.00078 9.9999 2.00078C9.00579 2.00078 8.1999 2.80667 8.1999 3.80078H11.7999ZM6.9999 3.80078C6.9999 2.14393 8.34305 0.800781 9.9999 0.800781C11.6567 0.800781 12.9999 2.14393 12.9999 3.80078H18.9999C19.3312 3.80078 19.5999 4.06941 19.5999 4.40078C19.5999 4.73215 19.3312 5.00078 18.9999 5.00078H17.7346L16.7654 13.4008H15.5575L16.5267 5.00078H3.47311L4.88947 17.2758C5.0292 18.4869 6.0546 19.4008 7.27366 19.4008H10.1024C10.036 19.5885 9.9999 19.7904 9.9999 20.0008C9.9999 20.2111 10.036 20.4131 10.1024 20.6008H7.27366C5.44507 20.6008 3.90697 19.2299 3.69738 17.4135L2.26515 5.00078H0.999902C0.668534 5.00078 0.399902 4.73215 0.399902 4.40078C0.399902 4.06941 0.668534 3.80078 0.999902 3.80078H6.9999ZM11.7999 14.6008C11.4686 14.6008 11.1999 14.8695 11.1999 15.2008C11.1999 15.5321 11.4686 15.8008 11.7999 15.8008H18.9999C19.3312 15.8008 19.5999 15.5321 19.5999 15.2008C19.5999 14.8695 19.3312 14.6008 18.9999 14.6008H11.7999ZM11.7999 17.0008C11.4686 17.0008 11.1999 17.2695 11.1999 17.6008C11.1999 17.9321 11.4686 18.2008 11.7999 18.2008H18.9999C19.3312 18.2008 19.5999 17.9321 19.5999 17.6008C19.5999 17.2695 19.3312 17.0008 18.9999 17.0008H11.7999ZM11.7999 19.4008C11.4686 19.4008 11.1999 19.6695 11.1999 20.0008C11.1999 20.3321 11.4686 20.6008 11.7999 20.6008H18.9999C19.3312 20.6008 19.5999 20.3321 19.5999 20.0008C19.5999 19.6695 19.3312 19.4008 18.9999 19.4008H11.7999Z"
                                fill="#C30532"
                              />
                            </svg>
                            <p>Remove</p>
                          </button>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </AdminStructureLocalData>
          )} */}
        </AdminMenu2>
      )}
      {isActive === 3 && (
        <AdminMenu2
          menu2Active={menu2Active}
          setMenu2Active={setMenu2Active}
          menu={[
            {
              id: 1,
              icon: (
                <svg
                  width="18"
                  height="14"
                  viewBox="0 0 20 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.25 0C1.00736 0 0 1.00736 0 2.25V4.75C0 5.99264 1.00736 7 2.25 7H4.75C5.99264 7 7 5.99264 7 4.75V2.25C7 1.00736 5.99264 0 4.75 0H2.25ZM1.5 2.25C1.5 1.83579 1.83579 1.5 2.25 1.5H4.75C5.16421 1.5 5.5 1.83579 5.5 2.25V4.75C5.5 5.16421 5.16421 5.5 4.75 5.5H2.25C1.83579 5.5 1.5 5.16421 1.5 4.75V2.25ZM9.25 1C8.8358 1 8.5 1.33579 8.5 1.75C8.5 2.16421 8.8358 2.5 9.25 2.5H19.25C19.6642 2.5 20 2.16421 20 1.75C20 1.33579 19.6642 1 19.25 1H9.25ZM9.25 4C8.8358 4 8.5 4.33579 8.5 4.75C8.5 5.16421 8.8358 5.5 9.25 5.5H16.25C16.6642 5.5 17 5.16421 17 4.75C17 4.33579 16.6642 4 16.25 4H9.25ZM2.25 9C1.00736 9 0 10.0074 0 11.25V13.75C0 14.9926 1.00736 16 2.25 16H4.75C5.99264 16 7 14.9926 7 13.75V11.25C7 10.0074 5.99264 9 4.75 9H2.25ZM1.5 11.25C1.5 10.8358 1.83579 10.5 2.25 10.5H4.75C5.16421 10.5 5.5 10.8358 5.5 11.25V13.75C5.5 14.1642 5.16421 14.5 4.75 14.5H2.25C1.83579 14.5 1.5 14.1642 1.5 13.75V11.25ZM9.25 10C8.8358 10 8.5 10.3358 8.5 10.75C8.5 11.1642 8.8358 11.5 9.25 11.5H19.25C19.6642 11.5 20 11.1642 20 10.75C20 10.3358 19.6642 10 19.25 10H9.25ZM9.25 13C8.8358 13 8.5 13.3358 8.5 13.75C8.5 14.1642 8.8358 14.5 9.25 14.5H16.25C16.6642 14.5 17 14.1642 17 13.75C17 13.3358 16.6642 13 16.25 13H9.25Z"
                    fill={menu2Active === 1 ? "#C30532" : "#474747"}
                  />
                </svg>
              ),
              title: "Available content",
            },

            {
              id: 2,
              icon: (
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.7498 0C9.1295 0 9.4434 0.28201 9.4931 0.64808L9.5 0.74985L9.5012 8H16.7543C17.1685 8 17.5043 8.3358 17.5043 8.75C17.5043 9.1297 17.2221 9.4435 16.8561 9.4932L16.7543 9.5H9.5012L9.5032 16.7491C9.5033 17.1633 9.1676 17.4993 8.7534 17.4993C8.3737 17.4993 8.0598 17.2173 8.0101 16.8512L8.0032 16.7494L8.0012 9.5H0.752197C0.337977 9.5 0.00219727 9.1642 0.00219727 8.75C0.00219727 8.3703 0.284347 8.0565 0.650427 8.0068L0.752197 8H8.0012L8 0.75015C7.9999 0.33594 8.3356 0 8.7498 0Z"
                    fill={menu2Active === 2 ? "#C30532" : "#474747"}
                  />
                </svg>
              ),
              title: "Add new content",
            },
          ]}
          setMenuLength={setMenuLength}
          setEditMode={setEditMode}
          setFilePreview={setFilePreview}
          setCreateFundHer={setCreateFundHer}
        >
          {menu2Active === 1 && (
            <AdminMindfulness
              confirmBox2={confirmBox2}
              setConfirmBox2={setConfirmBox2}
              setItemToEdit={setItemToEdit}
              setEditMode={setEditMode}
              setMenu2Active={setMenu2Active}
              menuLength={menuLength}
              setArticleLink={setArticleLink}
              setFilePreview={setFilePreview}
              setcoverImagePreview={setcoverImagePreview}
              eventID={eventID}
              setEventID={setEventID}
              isActive={isActive}
              menu2Active={menu2Active}
            />
          )}
          {menu2Active === 2 && (
            <AdminResourcesAndMindfulnessForm
              setMenu2Active={setMenu2Active}
              itemToEdit={itemToEdit}
              editMode={editMode}
              setEditMode={setEditMode}
              articleLink={articleLink}
              filePreview={filePreview}
              setFilePreview={setFilePreview}
              coverImagePreview={coverImagePreview}
              setcoverImagePreview={setcoverImagePreview}
              createAPI={
                "https://9jadelivery.com/femaletechMobile/public/api/createMindfulness"
              }
              editAPI={
                "https://9jadelivery.com/femaletechMobile/public/api/editMindfulness"
              }
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
          )}
        </AdminMenu2>
      )}
      {isActive === 4 && (
        <AdminMenu2
          menu2Active={menu2Active}
          setMenu2Active={setMenu2Active}
          menu={[
            {
              id: 1,
              icon: (
                <svg
                  width="18"
                  height="14"
                  viewBox="0 0 20 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.25 0C1.00736 0 0 1.00736 0 2.25V4.75C0 5.99264 1.00736 7 2.25 7H4.75C5.99264 7 7 5.99264 7 4.75V2.25C7 1.00736 5.99264 0 4.75 0H2.25ZM1.5 2.25C1.5 1.83579 1.83579 1.5 2.25 1.5H4.75C5.16421 1.5 5.5 1.83579 5.5 2.25V4.75C5.5 5.16421 5.16421 5.5 4.75 5.5H2.25C1.83579 5.5 1.5 5.16421 1.5 4.75V2.25ZM9.25 1C8.8358 1 8.5 1.33579 8.5 1.75C8.5 2.16421 8.8358 2.5 9.25 2.5H19.25C19.6642 2.5 20 2.16421 20 1.75C20 1.33579 19.6642 1 19.25 1H9.25ZM9.25 4C8.8358 4 8.5 4.33579 8.5 4.75C8.5 5.16421 8.8358 5.5 9.25 5.5H16.25C16.6642 5.5 17 5.16421 17 4.75C17 4.33579 16.6642 4 16.25 4H9.25ZM2.25 9C1.00736 9 0 10.0074 0 11.25V13.75C0 14.9926 1.00736 16 2.25 16H4.75C5.99264 16 7 14.9926 7 13.75V11.25C7 10.0074 5.99264 9 4.75 9H2.25ZM1.5 11.25C1.5 10.8358 1.83579 10.5 2.25 10.5H4.75C5.16421 10.5 5.5 10.8358 5.5 11.25V13.75C5.5 14.1642 5.16421 14.5 4.75 14.5H2.25C1.83579 14.5 1.5 14.1642 1.5 13.75V11.25ZM9.25 10C8.8358 10 8.5 10.3358 8.5 10.75C8.5 11.1642 8.8358 11.5 9.25 11.5H19.25C19.6642 11.5 20 11.1642 20 10.75C20 10.3358 19.6642 10 19.25 10H9.25ZM9.25 13C8.8358 13 8.5 13.3358 8.5 13.75C8.5 14.1642 8.8358 14.5 9.25 14.5H16.25C16.6642 14.5 17 14.1642 17 13.75C17 13.3358 16.6642 13 16.25 13H9.25Z"
                    fill={menu2Active === 1 ? "#C30532" : "#474747"}
                  />
                </svg>
              ),
              title: "Available content",
            },

            {
              id: 2,
              icon: (
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.7498 0C9.1295 0 9.4434 0.28201 9.4931 0.64808L9.5 0.74985L9.5012 8H16.7543C17.1685 8 17.5043 8.3358 17.5043 8.75C17.5043 9.1297 17.2221 9.4435 16.8561 9.4932L16.7543 9.5H9.5012L9.5032 16.7491C9.5033 17.1633 9.1676 17.4993 8.7534 17.4993C8.3737 17.4993 8.0598 17.2173 8.0101 16.8512L8.0032 16.7494L8.0012 9.5H0.752197C0.337977 9.5 0.00219727 9.1642 0.00219727 8.75C0.00219727 8.3703 0.284347 8.0565 0.650427 8.0068L0.752197 8H8.0012L8 0.75015C7.9999 0.33594 8.3356 0 8.7498 0Z"
                    fill={menu2Active === 2 ? "#C30532" : "#474747"}
                  />
                </svg>
              ),
              title: "Add new content",
            },
          ]}
          setMenuLength={setMenuLength}
          setEditMode={setEditMode}
          setFilePreview={setFilePreview}
          setCreateFundHer={setCreateFundHer}
        >
          {menu2Active === 1 && (
            <AdminResources
              confirmBox2={confirmBox2}
              setConfirmBox2={setConfirmBox2}
              setItemToEdit={setItemToEdit}
              setEditMode={setEditMode}
              setMenu2Active={setMenu2Active}
              menuLength={menuLength}
              setArticleLink={setArticleLink}
              setFilePreview={setFilePreview}
              setcoverImagePreview={setcoverImagePreview}
              eventID={eventID}
              setEventID={setEventID}
              isActive={isActive}
              menu2Active={menu2Active}
            />
          )}
          {menu2Active === 2 && (
            <AdminResourcesAndMindfulnessForm
              setMenu2Active={setMenu2Active}
              itemToEdit={itemToEdit}
              editMode={editMode}
              setEditMode={setEditMode}
              articleLink={articleLink}
              filePreview={filePreview}
              setFilePreview={setFilePreview}
              coverImagePreview={coverImagePreview}
              setcoverImagePreview={setcoverImagePreview}
              createAPI={
                "https://9jadelivery.com/femaletechMobile/public/api/createResources"
              }
              editAPI={
                "https://9jadelivery.com/femaletechMobile/public/api/editResources"
              }
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
          )}
        </AdminMenu2>
      )}
      {isActive === 5 && (
        <AdminMenu2
          menu2Active={menu2Active}
          setMenu2Active={setMenu2Active}
          menu={[
            {
              id: 1,
              icon: (
                <svg
                  width="18"
                  height="14"
                  viewBox="0 0 20 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.25 0C1.00736 0 0 1.00736 0 2.25V4.75C0 5.99264 1.00736 7 2.25 7H4.75C5.99264 7 7 5.99264 7 4.75V2.25C7 1.00736 5.99264 0 4.75 0H2.25ZM1.5 2.25C1.5 1.83579 1.83579 1.5 2.25 1.5H4.75C5.16421 1.5 5.5 1.83579 5.5 2.25V4.75C5.5 5.16421 5.16421 5.5 4.75 5.5H2.25C1.83579 5.5 1.5 5.16421 1.5 4.75V2.25ZM9.25 1C8.8358 1 8.5 1.33579 8.5 1.75C8.5 2.16421 8.8358 2.5 9.25 2.5H19.25C19.6642 2.5 20 2.16421 20 1.75C20 1.33579 19.6642 1 19.25 1H9.25ZM9.25 4C8.8358 4 8.5 4.33579 8.5 4.75C8.5 5.16421 8.8358 5.5 9.25 5.5H16.25C16.6642 5.5 17 5.16421 17 4.75C17 4.33579 16.6642 4 16.25 4H9.25ZM2.25 9C1.00736 9 0 10.0074 0 11.25V13.75C0 14.9926 1.00736 16 2.25 16H4.75C5.99264 16 7 14.9926 7 13.75V11.25C7 10.0074 5.99264 9 4.75 9H2.25ZM1.5 11.25C1.5 10.8358 1.83579 10.5 2.25 10.5H4.75C5.16421 10.5 5.5 10.8358 5.5 11.25V13.75C5.5 14.1642 5.16421 14.5 4.75 14.5H2.25C1.83579 14.5 1.5 14.1642 1.5 13.75V11.25ZM9.25 10C8.8358 10 8.5 10.3358 8.5 10.75C8.5 11.1642 8.8358 11.5 9.25 11.5H19.25C19.6642 11.5 20 11.1642 20 10.75C20 10.3358 19.6642 10 19.25 10H9.25ZM9.25 13C8.8358 13 8.5 13.3358 8.5 13.75C8.5 14.1642 8.8358 14.5 9.25 14.5H16.25C16.6642 14.5 17 14.1642 17 13.75C17 13.3358 16.6642 13 16.25 13H9.25Z"
                    fill={menu2Active === 1 ? "#C30532" : "#474747"}
                  />
                </svg>
              ),
              title: "Available podcast",
            },

            {
              id: 2,
              icon: (
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.7498 0C9.1295 0 9.4434 0.28201 9.4931 0.64808L9.5 0.74985L9.5012 8H16.7543C17.1685 8 17.5043 8.3358 17.5043 8.75C17.5043 9.1297 17.2221 9.4435 16.8561 9.4932L16.7543 9.5H9.5012L9.5032 16.7491C9.5033 17.1633 9.1676 17.4993 8.7534 17.4993C8.3737 17.4993 8.0598 17.2173 8.0101 16.8512L8.0032 16.7494L8.0012 9.5H0.752197C0.337977 9.5 0.00219727 9.1642 0.00219727 8.75C0.00219727 8.3703 0.284347 8.0565 0.650427 8.0068L0.752197 8H8.0012L8 0.75015C7.9999 0.33594 8.3356 0 8.7498 0Z"
                    fill={menu2Active === 2 ? "#C30532" : "#474747"}
                  />
                </svg>
              ),
              title: "Add new podcast",
            },
          ]}
          setMenuLength={setMenuLength}
          setEditMode={setEditMode}
          setFilePreview={setFilePreview}
          setCreateFundHer={setCreateFundHer}
        >
          {menu2Active === 1 && (
            <AdminPodcast
              confirmBox2={confirmBox2}
              setConfirmBox2={setConfirmBox2}
              setItemToEdit={setItemToEdit}
              setEditMode={setEditMode}
              setMenu2Active={setMenu2Active}
              menuLength={menuLength}
              setArticleLink={setArticleLink}
              setFilePreview={setFilePreview}
              setcoverImagePreview={setcoverImagePreview}
              eventID={eventID}
              setEventID={setEventID}
              isActive={isActive}
              menu2Active={menu2Active}
            />
          )}
          {menu2Active === 2 && (
            <AdminPodcastForm
              setMenu2Active={setMenu2Active}
              itemToEdit={itemToEdit}
              editMode={editMode}
              setEditMode={setEditMode}
              articleLink={articleLink}
              filePreview={filePreview}
              setFilePreview={setFilePreview}
              coverImagePreview={coverImagePreview}
              setcoverImagePreview={setcoverImagePreview}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
          )}
        </AdminMenu2>
      )}
      {isActive === 6 && <AdminChatSupport />}
      {isActive === 7 && (
        <AdminMenu2
          menu2Active={menu2Active}
          setMenu2Active={setMenu2Active}
          menu={[
            {
              id: 1,
              icon: (
                <svg
                  width="18"
                  height="14"
                  viewBox="0 0 20 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.25 0C1.00736 0 0 1.00736 0 2.25V4.75C0 5.99264 1.00736 7 2.25 7H4.75C5.99264 7 7 5.99264 7 4.75V2.25C7 1.00736 5.99264 0 4.75 0H2.25ZM1.5 2.25C1.5 1.83579 1.83579 1.5 2.25 1.5H4.75C5.16421 1.5 5.5 1.83579 5.5 2.25V4.75C5.5 5.16421 5.16421 5.5 4.75 5.5H2.25C1.83579 5.5 1.5 5.16421 1.5 4.75V2.25ZM9.25 1C8.8358 1 8.5 1.33579 8.5 1.75C8.5 2.16421 8.8358 2.5 9.25 2.5H19.25C19.6642 2.5 20 2.16421 20 1.75C20 1.33579 19.6642 1 19.25 1H9.25ZM9.25 4C8.8358 4 8.5 4.33579 8.5 4.75C8.5 5.16421 8.8358 5.5 9.25 5.5H16.25C16.6642 5.5 17 5.16421 17 4.75C17 4.33579 16.6642 4 16.25 4H9.25ZM2.25 9C1.00736 9 0 10.0074 0 11.25V13.75C0 14.9926 1.00736 16 2.25 16H4.75C5.99264 16 7 14.9926 7 13.75V11.25C7 10.0074 5.99264 9 4.75 9H2.25ZM1.5 11.25C1.5 10.8358 1.83579 10.5 2.25 10.5H4.75C5.16421 10.5 5.5 10.8358 5.5 11.25V13.75C5.5 14.1642 5.16421 14.5 4.75 14.5H2.25C1.83579 14.5 1.5 14.1642 1.5 13.75V11.25ZM9.25 10C8.8358 10 8.5 10.3358 8.5 10.75C8.5 11.1642 8.8358 11.5 9.25 11.5H19.25C19.6642 11.5 20 11.1642 20 10.75C20 10.3358 19.6642 10 19.25 10H9.25ZM9.25 13C8.8358 13 8.5 13.3358 8.5 13.75C8.5 14.1642 8.8358 14.5 9.25 14.5H16.25C16.6642 14.5 17 14.1642 17 13.75C17 13.3358 16.6642 13 16.25 13H9.25Z"
                    fill={menu2Active === 1 ? "#C30532" : "#474747"}
                  />
                </svg>
              ),
              title: "Uploaded spotlight banner",
            },

            {
              id: 2,
              icon: (
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.7498 0C9.1295 0 9.4434 0.28201 9.4931 0.64808L9.5 0.74985L9.5012 8H16.7543C17.1685 8 17.5043 8.3358 17.5043 8.75C17.5043 9.1297 17.2221 9.4435 16.8561 9.4932L16.7543 9.5H9.5012L9.5032 16.7491C9.5033 17.1633 9.1676 17.4993 8.7534 17.4993C8.3737 17.4993 8.0598 17.2173 8.0101 16.8512L8.0032 16.7494L8.0012 9.5H0.752197C0.337977 9.5 0.00219727 9.1642 0.00219727 8.75C0.00219727 8.3703 0.284347 8.0565 0.650427 8.0068L0.752197 8H8.0012L8 0.75015C7.9999 0.33594 8.3356 0 8.7498 0Z"
                    fill={menu2Active === 2 ? "#C30532" : "#474747"}
                  />
                </svg>
              ),
              title: "Add new banner",
            },
          ]}
          setMenuLength={setMenuLength}
          setEditMode={setEditMode}
          setFilePreview={setFilePreview}
          setCreateFundHer={setCreateFundHer}
        >
          {menu2Active === 1 && (
            <AdminSpotlight
              confirmBox2={confirmBox2}
              setConfirmBox2={setConfirmBox2}
              setItemToEdit={setItemToEdit}
              setEditMode={setEditMode}
              setMenu2Active={setMenu2Active}
              menuLength={menuLength}
              setArticleLink={setArticleLink}
              setFilePreview={setFilePreview}
              setcoverImagePreview={setcoverImagePreview}
              eventID={eventID}
              setEventID={setEventID}
              isActive={isActive}
              menu2Active={menu2Active}
            />
          )}
          {menu2Active === 2 && (
            <AdminSpotlightForm
              setMenu2Active={setMenu2Active}
              itemToEdit={itemToEdit}
              editMode={editMode}
              setEditMode={setEditMode}
              articleLink={articleLink}
              filePreview={filePreview}
              setFilePreview={setFilePreview}
              coverImagePreview={coverImagePreview}
              setcoverImagePreview={setcoverImagePreview}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
          )}
        </AdminMenu2>
      )}
      {isActive === 8 && (
        <AdminMenu2
          menu2Active={menu2Active}
          setMenu2Active={setMenu2Active}
          menu={[
            {
              id: 1,
              icon: (
                <svg
                  width="18"
                  height="14"
                  viewBox="0 0 20 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.25 0C1.00736 0 0 1.00736 0 2.25V4.75C0 5.99264 1.00736 7 2.25 7H4.75C5.99264 7 7 5.99264 7 4.75V2.25C7 1.00736 5.99264 0 4.75 0H2.25ZM1.5 2.25C1.5 1.83579 1.83579 1.5 2.25 1.5H4.75C5.16421 1.5 5.5 1.83579 5.5 2.25V4.75C5.5 5.16421 5.16421 5.5 4.75 5.5H2.25C1.83579 5.5 1.5 5.16421 1.5 4.75V2.25ZM9.25 1C8.8358 1 8.5 1.33579 8.5 1.75C8.5 2.16421 8.8358 2.5 9.25 2.5H19.25C19.6642 2.5 20 2.16421 20 1.75C20 1.33579 19.6642 1 19.25 1H9.25ZM9.25 4C8.8358 4 8.5 4.33579 8.5 4.75C8.5 5.16421 8.8358 5.5 9.25 5.5H16.25C16.6642 5.5 17 5.16421 17 4.75C17 4.33579 16.6642 4 16.25 4H9.25ZM2.25 9C1.00736 9 0 10.0074 0 11.25V13.75C0 14.9926 1.00736 16 2.25 16H4.75C5.99264 16 7 14.9926 7 13.75V11.25C7 10.0074 5.99264 9 4.75 9H2.25ZM1.5 11.25C1.5 10.8358 1.83579 10.5 2.25 10.5H4.75C5.16421 10.5 5.5 10.8358 5.5 11.25V13.75C5.5 14.1642 5.16421 14.5 4.75 14.5H2.25C1.83579 14.5 1.5 14.1642 1.5 13.75V11.25ZM9.25 10C8.8358 10 8.5 10.3358 8.5 10.75C8.5 11.1642 8.8358 11.5 9.25 11.5H19.25C19.6642 11.5 20 11.1642 20 10.75C20 10.3358 19.6642 10 19.25 10H9.25ZM9.25 13C8.8358 13 8.5 13.3358 8.5 13.75C8.5 14.1642 8.8358 14.5 9.25 14.5H16.25C16.6642 14.5 17 14.1642 17 13.75C17 13.3358 16.6642 13 16.25 13H9.25Z"
                    fill={menu2Active === 1 ? "#C30532" : "#474747"}
                  />
                </svg>
              ),
              title: "Listed subscription plans",
            },
            {
              id: 2,
              icon: (
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.7498 0C9.1295 0 9.4434 0.28201 9.4931 0.64808L9.5 0.74985L9.5012 8H16.7543C17.1685 8 17.5043 8.3358 17.5043 8.75C17.5043 9.1297 17.2221 9.4435 16.8561 9.4932L16.7543 9.5H9.5012L9.5032 16.7491C9.5033 17.1633 9.1676 17.4993 8.7534 17.4993C8.3737 17.4993 8.0598 17.2173 8.0101 16.8512L8.0032 16.7494L8.0012 9.5H0.752197C0.337977 9.5 0.00219727 9.1642 0.00219727 8.75C0.00219727 8.3703 0.284347 8.0565 0.650427 8.0068L0.752197 8H8.0012L8 0.75015C7.9999 0.33594 8.3356 0 8.7498 0Z"
                    fill={menu2Active === 2 ? "#C30532" : "#474747"}
                  />
                </svg>
              ),
              title: "Add new subscription plan",
            },
          ]}
          setMenuLength={setMenuLength}
          setEditMode={setEditMode}
          setFilePreview={setFilePreview}
          setCreateFundHer={setCreateFundHer}
        >
          {menu2Active === 1 && (
            <AdminStructure1
              api="https://9jadelivery.com/femaletechMobile/public/api/getFetechPackages"
              setApiData={setApiData}
              setIsLoading={setIsLoading}
              setErrorMessage={setErrorMessage}
            >
              <table>
                <thead>
                  <tr>
                    <th>S/N</th>
                    <th>Subscription Plan</th>

                    <th>Currency</th>
                    <th></th>
                    <th
                      style={{
                        paddingLeft: "1rem",
                      }}
                    >
                      Amount
                    </th>
                    <th></th>
                    <th>Role</th>
                    <th>State</th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {errorMessage && (
                    <ErrorMessage
                      message={`Error fetching program data : ${errorMessage}`}
                    />
                  )}
                  {isLoading ? (
                    <Loader />
                  ) : (
                    apiData.map((item, index) => (
                      <tr key={index}>
                        <td>{item.id}</td>
                        <td
                          style={{
                            textAlign: "center",
                          }}
                        >
                          <h3>{item.planType}</h3>
                        </td>

                        <td>{item.currency}</td>
                        <td></td>

                        <td>{item.amount}</td>
                        <td
                          style={{
                            padding: "1rem",
                          }}
                        ></td>
                        <td
                          style={{
                            textAlign: "center",
                          }}
                        >
                          {item.role}
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                          }}
                        >
                          {item.state}
                        </td>
                        <td></td>
                        <td>
                          <button
                            onClick={() =>
                              handleEdit(
                                item.id,
                                "https://9jadelivery.com/femaletechMobile/public/api/getFetechPackages"
                              )
                            }
                          >
                            <svg
                              width="20"
                              height="21"
                              viewBox="0 0 20 21"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M18.9519 1.0481C17.5543 -0.349421 15.2885 -0.349361 13.8911 1.04825L1.94103 12.9997C1.5347 13.4061 1.2491 13.9172 1.116 14.4762L0.0204088 19.0777C-0.0399112 19.3311 0.0355188 19.5976 0.219679 19.7817C0.403849 19.9659 0.670369 20.0413 0.923729 19.981L5.52498 18.8855C6.08418 18.7523 6.59546 18.4666 7.00191 18.0601L8.1531 16.9088C8.0528 16.4552 8 15.9838 8 15.5C8 15.3026 8.0088 15.1073 8.026 14.9144L5.94119 16.9995C5.73104 17.2097 5.46668 17.3574 5.17755 17.4263L1.76191 18.2395L2.57521 14.8237C2.64402 14.5346 2.79168 14.2704 3.00175 14.0603L13 4.06084L15.9394 7.00018L13.9137 9.0261C14.1068 9.0088 14.3024 9 14.5 9C14.9835 9 15.4547 9.0528 15.9081 9.1529L18.952 6.10861C20.3493 4.71112 20.3493 2.4455 18.9519 1.0481ZM14.9518 2.10884C15.7634 1.29709 17.0795 1.29705 17.8912 2.10876C18.7028 2.9204 18.7029 4.23632 17.8913 5.04801L17 5.93946L14.0606 3.00012L14.9518 2.10884ZM20 15.5C20 18.5376 17.5376 21 14.5 21C11.4624 21 9 18.5376 9 15.5C9 12.4624 11.4624 10 14.5 10C17.5376 10 20 12.4624 20 15.5ZM10.5 15.5C10.5 16.3335 10.755 17.1075 11.1911 17.7482L16.7482 12.1911C16.1075 11.755 15.3335 11.5 14.5 11.5C12.2909 11.5 10.5 13.2909 10.5 15.5ZM14.5 19.5C16.7091 19.5 18.5 17.7091 18.5 15.5C18.5 14.6665 18.245 13.8925 17.8089 13.2518L12.2518 18.8089C12.8925 19.245 13.6665 19.5 14.5 19.5Z"
                                fill="#C30532"
                              />
                            </svg>
                            <p>Edit</p>
                          </button>
                        </td>
                        <td>
                          <button
                            onClick={() => {
                              setConfirmBox2(true);
                              setEventID(item.id);
                            }}
                          >
                            <svg
                              width="20"
                              height="21"
                              viewBox="0 0 20 21"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M11.7999 3.80078C11.7999 2.80667 10.994 2.00078 9.9999 2.00078C9.00579 2.00078 8.1999 2.80667 8.1999 3.80078H11.7999ZM6.9999 3.80078C6.9999 2.14393 8.34305 0.800781 9.9999 0.800781C11.6567 0.800781 12.9999 2.14393 12.9999 3.80078H18.9999C19.3312 3.80078 19.5999 4.06941 19.5999 4.40078C19.5999 4.73215 19.3312 5.00078 18.9999 5.00078H17.7346L16.7654 13.4008H15.5575L16.5267 5.00078H3.47311L4.88947 17.2758C5.0292 18.4869 6.0546 19.4008 7.27366 19.4008H10.1024C10.036 19.5885 9.9999 19.7904 9.9999 20.0008C9.9999 20.2111 10.036 20.4131 10.1024 20.6008H7.27366C5.44507 20.6008 3.90697 19.2299 3.69738 17.4135L2.26515 5.00078H0.999902C0.668534 5.00078 0.399902 4.73215 0.399902 4.40078C0.399902 4.06941 0.668534 3.80078 0.999902 3.80078H6.9999ZM11.7999 14.6008C11.4686 14.6008 11.1999 14.8695 11.1999 15.2008C11.1999 15.5321 11.4686 15.8008 11.7999 15.8008H18.9999C19.3312 15.8008 19.5999 15.5321 19.5999 15.2008C19.5999 14.8695 19.3312 14.6008 18.9999 14.6008H11.7999ZM11.7999 17.0008C11.4686 17.0008 11.1999 17.2695 11.1999 17.6008C11.1999 17.9321 11.4686 18.2008 11.7999 18.2008H18.9999C19.3312 18.2008 19.5999 17.9321 19.5999 17.6008C19.5999 17.2695 19.3312 17.0008 18.9999 17.0008H11.7999ZM11.7999 19.4008C11.4686 19.4008 11.1999 19.6695 11.1999 20.0008C11.1999 20.3321 11.4686 20.6008 11.7999 20.6008H18.9999C19.3312 20.6008 19.5999 20.3321 19.5999 20.0008C19.5999 19.6695 19.3312 19.4008 18.9999 19.4008H11.7999Z"
                                fill="#C30532"
                              />
                            </svg>
                            <p>Delete</p>
                          </button>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
              {confirmBox2 && (
                <DeleteConfirmation
                  setConfirmBox2={setConfirmBox2}
                  eventID={eventID}
                  api={
                    "https://9jadelivery.com/femaletechMobile/public/api/deleteFetechPackages"
                  }
                  setEditMode={setEditMode}
                  isActive={isActive}
                  menu2Active={menu2Active}
                  setMenu2Active={setMenu2Active}
                  menuLength={menuLength}
                />
              )}
            </AdminStructure1>
          )}
          {menu2Active === 2 && (
            <AdminSubscriptionPlanForm
              setMenu2Active={setMenu2Active}
              itemToEdit={itemToEdit}
              editMode={editMode}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
          )}
        </AdminMenu2>
      )}
      {isActive === 9 && (
        <AdminMenu2
          menu2Active={menu2Active}
          setMenu2Active={setMenu2Active}
          menu={[
            {
              id: 1,
              icon: (
                <svg
                  width="18"
                  height="14"
                  viewBox="0 0 20 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.25 0C1.00736 0 0 1.00736 0 2.25V4.75C0 5.99264 1.00736 7 2.25 7H4.75C5.99264 7 7 5.99264 7 4.75V2.25C7 1.00736 5.99264 0 4.75 0H2.25ZM1.5 2.25C1.5 1.83579 1.83579 1.5 2.25 1.5H4.75C5.16421 1.5 5.5 1.83579 5.5 2.25V4.75C5.5 5.16421 5.16421 5.5 4.75 5.5H2.25C1.83579 5.5 1.5 5.16421 1.5 4.75V2.25ZM9.25 1C8.8358 1 8.5 1.33579 8.5 1.75C8.5 2.16421 8.8358 2.5 9.25 2.5H19.25C19.6642 2.5 20 2.16421 20 1.75C20 1.33579 19.6642 1 19.25 1H9.25ZM9.25 4C8.8358 4 8.5 4.33579 8.5 4.75C8.5 5.16421 8.8358 5.5 9.25 5.5H16.25C16.6642 5.5 17 5.16421 17 4.75C17 4.33579 16.6642 4 16.25 4H9.25ZM2.25 9C1.00736 9 0 10.0074 0 11.25V13.75C0 14.9926 1.00736 16 2.25 16H4.75C5.99264 16 7 14.9926 7 13.75V11.25C7 10.0074 5.99264 9 4.75 9H2.25ZM1.5 11.25C1.5 10.8358 1.83579 10.5 2.25 10.5H4.75C5.16421 10.5 5.5 10.8358 5.5 11.25V13.75C5.5 14.1642 5.16421 14.5 4.75 14.5H2.25C1.83579 14.5 1.5 14.1642 1.5 13.75V11.25ZM9.25 10C8.8358 10 8.5 10.3358 8.5 10.75C8.5 11.1642 8.8358 11.5 9.25 11.5H19.25C19.6642 11.5 20 11.1642 20 10.75C20 10.3358 19.6642 10 19.25 10H9.25ZM9.25 13C8.8358 13 8.5 13.3358 8.5 13.75C8.5 14.1642 8.8358 14.5 9.25 14.5H16.25C16.6642 14.5 17 14.1642 17 13.75C17 13.3358 16.6642 13 16.25 13H9.25Z"
                    fill={menu2Active === 1 ? "#C30532" : "#474747"}
                  />
                </svg>
              ),
              title: "Mentor Application",
            },

            {
              id: 2,
              icon: (
                <svg
                  width="18"
                  height="14"
                  viewBox="0 0 20 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.25 0C1.00736 0 0 1.00736 0 2.25V4.75C0 5.99264 1.00736 7 2.25 7H4.75C5.99264 7 7 5.99264 7 4.75V2.25C7 1.00736 5.99264 0 4.75 0H2.25ZM1.5 2.25C1.5 1.83579 1.83579 1.5 2.25 1.5H4.75C5.16421 1.5 5.5 1.83579 5.5 2.25V4.75C5.5 5.16421 5.16421 5.5 4.75 5.5H2.25C1.83579 5.5 1.5 5.16421 1.5 4.75V2.25ZM9.25 1C8.8358 1 8.5 1.33579 8.5 1.75C8.5 2.16421 8.8358 2.5 9.25 2.5H19.25C19.6642 2.5 20 2.16421 20 1.75C20 1.33579 19.6642 1 19.25 1H9.25ZM9.25 4C8.8358 4 8.5 4.33579 8.5 4.75C8.5 5.16421 8.8358 5.5 9.25 5.5H16.25C16.6642 5.5 17 5.16421 17 4.75C17 4.33579 16.6642 4 16.25 4H9.25ZM2.25 9C1.00736 9 0 10.0074 0 11.25V13.75C0 14.9926 1.00736 16 2.25 16H4.75C5.99264 16 7 14.9926 7 13.75V11.25C7 10.0074 5.99264 9 4.75 9H2.25ZM1.5 11.25C1.5 10.8358 1.83579 10.5 2.25 10.5H4.75C5.16421 10.5 5.5 10.8358 5.5 11.25V13.75C5.5 14.1642 5.16421 14.5 4.75 14.5H2.25C1.83579 14.5 1.5 14.1642 1.5 13.75V11.25ZM9.25 10C8.8358 10 8.5 10.3358 8.5 10.75C8.5 11.1642 8.8358 11.5 9.25 11.5H19.25C19.6642 11.5 20 11.1642 20 10.75C20 10.3358 19.6642 10 19.25 10H9.25ZM9.25 13C8.8358 13 8.5 13.3358 8.5 13.75C8.5 14.1642 8.8358 14.5 9.25 14.5H16.25C16.6642 14.5 17 14.1642 17 13.75C17 13.3358 16.6642 13 16.25 13H9.25Z"
                    fill={menu2Active === 2 ? "#C30532" : "#474747"}
                  />
                </svg>
              ),
              title: "Mentors",
            },

            {
              id: 3,
              icon: (
                <svg
                  width="18"
                  height="14"
                  viewBox="0 0 20 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.25 0C1.00736 0 0 1.00736 0 2.25V4.75C0 5.99264 1.00736 7 2.25 7H4.75C5.99264 7 7 5.99264 7 4.75V2.25C7 1.00736 5.99264 0 4.75 0H2.25ZM1.5 2.25C1.5 1.83579 1.83579 1.5 2.25 1.5H4.75C5.16421 1.5 5.5 1.83579 5.5 2.25V4.75C5.5 5.16421 5.16421 5.5 4.75 5.5H2.25C1.83579 5.5 1.5 5.16421 1.5 4.75V2.25ZM9.25 1C8.8358 1 8.5 1.33579 8.5 1.75C8.5 2.16421 8.8358 2.5 9.25 2.5H19.25C19.6642 2.5 20 2.16421 20 1.75C20 1.33579 19.6642 1 19.25 1H9.25ZM9.25 4C8.8358 4 8.5 4.33579 8.5 4.75C8.5 5.16421 8.8358 5.5 9.25 5.5H16.25C16.6642 5.5 17 5.16421 17 4.75C17 4.33579 16.6642 4 16.25 4H9.25ZM2.25 9C1.00736 9 0 10.0074 0 11.25V13.75C0 14.9926 1.00736 16 2.25 16H4.75C5.99264 16 7 14.9926 7 13.75V11.25C7 10.0074 5.99264 9 4.75 9H2.25ZM1.5 11.25C1.5 10.8358 1.83579 10.5 2.25 10.5H4.75C5.16421 10.5 5.5 10.8358 5.5 11.25V13.75C5.5 14.1642 5.16421 14.5 4.75 14.5H2.25C1.83579 14.5 1.5 14.1642 1.5 13.75V11.25ZM9.25 10C8.8358 10 8.5 10.3358 8.5 10.75C8.5 11.1642 8.8358 11.5 9.25 11.5H19.25C19.6642 11.5 20 11.1642 20 10.75C20 10.3358 19.6642 10 19.25 10H9.25ZM9.25 13C8.8358 13 8.5 13.3358 8.5 13.75C8.5 14.1642 8.8358 14.5 9.25 14.5H16.25C16.6642 14.5 17 14.1642 17 13.75C17 13.3358 16.6642 13 16.25 13H9.25Z"
                    fill={menu2Active === 3 ? "#C30532" : "#474747"}
                  />
                </svg>
              ),
              title: "Upcoming mentor session",
            },
          ]}
          setMenuLength={setMenuLength}
          setEditMode={setEditMode}
          setFilePreview={setFilePreview}
          setCreateFundHer={setCreateFundHer}
        >
          {menu2Active === 1 && (
            <MentorApplication
              allMentorInfo={allMentorInfo}
              setAllMentorInfo={setAllMentorInfo}
              apiData={apiData}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              errorMessage={errorMessage}
            />
          )}
          {menu2Active === 2 && (
            <AllMentors
              allMentorInfo={allMentorInfo}
              setAllMentorInfo={setAllMentorInfo}
              mentorReviews={mentorReviews}
              setMentorReviews={setMentorReviews}
              confirmBox={confirmBox}
              setConfirmBox={setConfirmBox}
              apiData={apiData}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              errorMessage={errorMessage}
            />
          )}
          {menu2Active === 3 && (
            <UpcomingMentorSession
              apiData={apiData}
              isLoading={isLoading}
              errorMessage={errorMessage}
            />
          )}
        </AdminMenu2>
      )}
      {isActive === 10 && (
        <AdminMenu2
          menu2Active={menu2Active}
          setMenu2Active={setMenu2Active}
          menu={[
            {
              id: 1,
              icon: (
                <svg
                  width="18"
                  height="14"
                  viewBox="0 0 20 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.25 0C1.00736 0 0 1.00736 0 2.25V4.75C0 5.99264 1.00736 7 2.25 7H4.75C5.99264 7 7 5.99264 7 4.75V2.25C7 1.00736 5.99264 0 4.75 0H2.25ZM1.5 2.25C1.5 1.83579 1.83579 1.5 2.25 1.5H4.75C5.16421 1.5 5.5 1.83579 5.5 2.25V4.75C5.5 5.16421 5.16421 5.5 4.75 5.5H2.25C1.83579 5.5 1.5 5.16421 1.5 4.75V2.25ZM9.25 1C8.8358 1 8.5 1.33579 8.5 1.75C8.5 2.16421 8.8358 2.5 9.25 2.5H19.25C19.6642 2.5 20 2.16421 20 1.75C20 1.33579 19.6642 1 19.25 1H9.25ZM9.25 4C8.8358 4 8.5 4.33579 8.5 4.75C8.5 5.16421 8.8358 5.5 9.25 5.5H16.25C16.6642 5.5 17 5.16421 17 4.75C17 4.33579 16.6642 4 16.25 4H9.25ZM2.25 9C1.00736 9 0 10.0074 0 11.25V13.75C0 14.9926 1.00736 16 2.25 16H4.75C5.99264 16 7 14.9926 7 13.75V11.25C7 10.0074 5.99264 9 4.75 9H2.25ZM1.5 11.25C1.5 10.8358 1.83579 10.5 2.25 10.5H4.75C5.16421 10.5 5.5 10.8358 5.5 11.25V13.75C5.5 14.1642 5.16421 14.5 4.75 14.5H2.25C1.83579 14.5 1.5 14.1642 1.5 13.75V11.25ZM9.25 10C8.8358 10 8.5 10.3358 8.5 10.75C8.5 11.1642 8.8358 11.5 9.25 11.5H19.25C19.6642 11.5 20 11.1642 20 10.75C20 10.3358 19.6642 10 19.25 10H9.25ZM9.25 13C8.8358 13 8.5 13.3358 8.5 13.75C8.5 14.1642 8.8358 14.5 9.25 14.5H16.25C16.6642 14.5 17 14.1642 17 13.75C17 13.3358 16.6642 13 16.25 13H9.25Z"
                    fill={menu2Active === 1 ? "#C30532" : "#474747"}
                  />
                </svg>
              ),
              title: "Listed service packages",
            },
            {
              id: 2,
              icon: (
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.7498 0C9.1295 0 9.4434 0.28201 9.4931 0.64808L9.5 0.74985L9.5012 8H16.7543C17.1685 8 17.5043 8.3358 17.5043 8.75C17.5043 9.1297 17.2221 9.4435 16.8561 9.4932L16.7543 9.5H9.5012L9.5032 16.7491C9.5033 17.1633 9.1676 17.4993 8.7534 17.4993C8.3737 17.4993 8.0598 17.2173 8.0101 16.8512L8.0032 16.7494L8.0012 9.5H0.752197C0.337977 9.5 0.00219727 9.1642 0.00219727 8.75C0.00219727 8.3703 0.284347 8.0565 0.650427 8.0068L0.752197 8H8.0012L8 0.75015C7.9999 0.33594 8.3356 0 8.7498 0Z"
                    fill={menu2Active === 2 ? "#C30532" : "#474747"}
                  />
                </svg>
              ),
              title: "Add new service package",
            },
          ]}
          setMenuLength={setMenuLength}
          setEditMode={setEditMode}
          setFilePreview={setFilePreview}
          setCreateFundHer={setCreateFundHer}
        >
          {menu2Active === 1 && (
            <AdminStructure1
              api="https://9jadelivery.com/femaletechMobile/public/api/getFetechPackageDeals"
              setApiData={setApiData}
              setIsLoading={setIsLoading}
              setErrorMessage={setErrorMessage}
            >
              <table>
                <thead>
                  <tr>
                    <th>S/N</th>
                    <th>Title</th>
                    <th></th>
                    <th colSpan="2">Currency</th>
                    <th></th>
                    <th colSpan="3">Amount</th>
                    <th></th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {errorMessage && (
                    <ErrorMessage
                      message={`Error fetching program data : ${errorMessage}`}
                    />
                  )}
                  {isLoading ? (
                    <Loader />
                  ) : (
                    apiData.map((item, index) => (
                      <tr key={index}>
                        <td>{item.id}</td>
                        <td
                          style={{
                            textAlign: "center",
                          }}
                        >
                          <h3>{item.title}</h3>
                        </td>
                        <td></td>
                        <td>{item.currency}</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td
                          style={{
                            textAlign: "center",
                          }}
                        >
                          {item.amount}
                        </td>
                        <td></td>
                        <td></td>
                        <td>
                          <button
                            onClick={() =>
                              handleEdit(
                                item.id,
                                "https://9jadelivery.com/femaletechMobile/public/api/getFetechPackageDeals"
                              )
                            }
                          >
                            <svg
                              width="20"
                              height="21"
                              viewBox="0 0 20 21"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M18.9519 1.0481C17.5543 -0.349421 15.2885 -0.349361 13.8911 1.04825L1.94103 12.9997C1.5347 13.4061 1.2491 13.9172 1.116 14.4762L0.0204088 19.0777C-0.0399112 19.3311 0.0355188 19.5976 0.219679 19.7817C0.403849 19.9659 0.670369 20.0413 0.923729 19.981L5.52498 18.8855C6.08418 18.7523 6.59546 18.4666 7.00191 18.0601L8.1531 16.9088C8.0528 16.4552 8 15.9838 8 15.5C8 15.3026 8.0088 15.1073 8.026 14.9144L5.94119 16.9995C5.73104 17.2097 5.46668 17.3574 5.17755 17.4263L1.76191 18.2395L2.57521 14.8237C2.64402 14.5346 2.79168 14.2704 3.00175 14.0603L13 4.06084L15.9394 7.00018L13.9137 9.0261C14.1068 9.0088 14.3024 9 14.5 9C14.9835 9 15.4547 9.0528 15.9081 9.1529L18.952 6.10861C20.3493 4.71112 20.3493 2.4455 18.9519 1.0481ZM14.9518 2.10884C15.7634 1.29709 17.0795 1.29705 17.8912 2.10876C18.7028 2.9204 18.7029 4.23632 17.8913 5.04801L17 5.93946L14.0606 3.00012L14.9518 2.10884ZM20 15.5C20 18.5376 17.5376 21 14.5 21C11.4624 21 9 18.5376 9 15.5C9 12.4624 11.4624 10 14.5 10C17.5376 10 20 12.4624 20 15.5ZM10.5 15.5C10.5 16.3335 10.755 17.1075 11.1911 17.7482L16.7482 12.1911C16.1075 11.755 15.3335 11.5 14.5 11.5C12.2909 11.5 10.5 13.2909 10.5 15.5ZM14.5 19.5C16.7091 19.5 18.5 17.7091 18.5 15.5C18.5 14.6665 18.245 13.8925 17.8089 13.2518L12.2518 18.8089C12.8925 19.245 13.6665 19.5 14.5 19.5Z"
                                fill="#C30532"
                              />
                            </svg>
                            <p>Edit</p>
                          </button>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </AdminStructure1>
          )}
          {menu2Active === 2 && (
            <ServicePackagesForm
              setMenu2Active={setMenu2Active}
              itemToEdit={itemToEdit}
              editMode={editMode}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
          )}
        </AdminMenu2>
      )}
      {isActive === 11 && (
        <AdminMenu2
          menu2Active={menu2Active}
          setMenu2Active={setMenu2Active}
          menu={[
            {
              id: 1,
              icon: (
                <svg
                  width="18"
                  height="14"
                  viewBox="0 0 20 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.25 0C1.00736 0 0 1.00736 0 2.25V4.75C0 5.99264 1.00736 7 2.25 7H4.75C5.99264 7 7 5.99264 7 4.75V2.25C7 1.00736 5.99264 0 4.75 0H2.25ZM1.5 2.25C1.5 1.83579 1.83579 1.5 2.25 1.5H4.75C5.16421 1.5 5.5 1.83579 5.5 2.25V4.75C5.5 5.16421 5.16421 5.5 4.75 5.5H2.25C1.83579 5.5 1.5 5.16421 1.5 4.75V2.25ZM9.25 1C8.8358 1 8.5 1.33579 8.5 1.75C8.5 2.16421 8.8358 2.5 9.25 2.5H19.25C19.6642 2.5 20 2.16421 20 1.75C20 1.33579 19.6642 1 19.25 1H9.25ZM9.25 4C8.8358 4 8.5 4.33579 8.5 4.75C8.5 5.16421 8.8358 5.5 9.25 5.5H16.25C16.6642 5.5 17 5.16421 17 4.75C17 4.33579 16.6642 4 16.25 4H9.25ZM2.25 9C1.00736 9 0 10.0074 0 11.25V13.75C0 14.9926 1.00736 16 2.25 16H4.75C5.99264 16 7 14.9926 7 13.75V11.25C7 10.0074 5.99264 9 4.75 9H2.25ZM1.5 11.25C1.5 10.8358 1.83579 10.5 2.25 10.5H4.75C5.16421 10.5 5.5 10.8358 5.5 11.25V13.75C5.5 14.1642 5.16421 14.5 4.75 14.5H2.25C1.83579 14.5 1.5 14.1642 1.5 13.75V11.25ZM9.25 10C8.8358 10 8.5 10.3358 8.5 10.75C8.5 11.1642 8.8358 11.5 9.25 11.5H19.25C19.6642 11.5 20 11.1642 20 10.75C20 10.3358 19.6642 10 19.25 10H9.25ZM9.25 13C8.8358 13 8.5 13.3358 8.5 13.75C8.5 14.1642 8.8358 14.5 9.25 14.5H16.25C16.6642 14.5 17 14.1642 17 13.75C17 13.3358 16.6642 13 16.25 13H9.25Z"
                    fill={menu2Active === 1 ? "#C30532" : "#474747"}
                  />
                </svg>
              ),
              title: "Pending",
            },

            {
              id: 2,
              icon: (
                <svg
                  width="18"
                  height="14"
                  viewBox="0 0 20 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.25 0C1.00736 0 0 1.00736 0 2.25V4.75C0 5.99264 1.00736 7 2.25 7H4.75C5.99264 7 7 5.99264 7 4.75V2.25C7 1.00736 5.99264 0 4.75 0H2.25ZM1.5 2.25C1.5 1.83579 1.83579 1.5 2.25 1.5H4.75C5.16421 1.5 5.5 1.83579 5.5 2.25V4.75C5.5 5.16421 5.16421 5.5 4.75 5.5H2.25C1.83579 5.5 1.5 5.16421 1.5 4.75V2.25ZM9.25 1C8.8358 1 8.5 1.33579 8.5 1.75C8.5 2.16421 8.8358 2.5 9.25 2.5H19.25C19.6642 2.5 20 2.16421 20 1.75C20 1.33579 19.6642 1 19.25 1H9.25ZM9.25 4C8.8358 4 8.5 4.33579 8.5 4.75C8.5 5.16421 8.8358 5.5 9.25 5.5H16.25C16.6642 5.5 17 5.16421 17 4.75C17 4.33579 16.6642 4 16.25 4H9.25ZM2.25 9C1.00736 9 0 10.0074 0 11.25V13.75C0 14.9926 1.00736 16 2.25 16H4.75C5.99264 16 7 14.9926 7 13.75V11.25C7 10.0074 5.99264 9 4.75 9H2.25ZM1.5 11.25C1.5 10.8358 1.83579 10.5 2.25 10.5H4.75C5.16421 10.5 5.5 10.8358 5.5 11.25V13.75C5.5 14.1642 5.16421 14.5 4.75 14.5H2.25C1.83579 14.5 1.5 14.1642 1.5 13.75V11.25ZM9.25 10C8.8358 10 8.5 10.3358 8.5 10.75C8.5 11.1642 8.8358 11.5 9.25 11.5H19.25C19.6642 11.5 20 11.1642 20 10.75C20 10.3358 19.6642 10 19.25 10H9.25ZM9.25 13C8.8358 13 8.5 13.3358 8.5 13.75C8.5 14.1642 8.8358 14.5 9.25 14.5H16.25C16.6642 14.5 17 14.1642 17 13.75C17 13.3358 16.6642 13 16.25 13H9.25Z"
                    fill={menu2Active === 2 ? "#C30532" : "#474747"}
                  />
                </svg>
              ),
              title: "Paid",
            },
          ]}
          setMenuLength={setMenuLength}
          setEditMode={setEditMode}
          setFilePreview={setFilePreview}
          setCreateFundHer={setCreateFundHer}
        >
          {menu2Active === 1 && (
            <WithdrawalRequest
              api="https://9jadelivery.com/femaletechMobile/public/api/withdrawalRequest"
              status="Pending"
              setApiData={setApiData}
              setIsLoading={setIsLoading}
              setErrorMessage={setErrorMessage}
            >
              <table>
                <thead>
                  <tr>
                    <th>S/N</th>
                    <th>Name</th>
                    <th></th>
                    <th colSpan="2">Amount</th>
                    <th></th>
                    <th colSpan="3">Date</th>
                    <th></th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {errorMessage && (
                    <ErrorMessage
                      message={`Error fetching program data : ${errorMessage}`}
                    />
                  )}
                  {isLoading ? (
                    <Loader />
                  ) : (
                    apiData?.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>

                        <td
                          style={{
                            textAlign: "center",
                          }}
                        >
                          <h3>{item.fullName}</h3>
                        </td>
                        <td></td>
                        <td
                          style={{
                            paddingLeft: "1rem",
                          }}
                        >
                          {item.currency}
                          {item.amount}
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>{item.date}</td>
                        <td></td>
                        <td></td>
                        <td>
                          <button
                            className="btn2Style"
                            onClick={() => {
                              setConfirmBox(true);
                              setCurrentItem(item);
                            }}
                            style={{
                              textTransform: "none",
                              fontWeight: "600",
                              fontFamily: "Lato, sans-serif",
                              fontSize: "1.2rem",
                              padding: "1rem 2rem",
                            }}
                          >
                            Make payment
                          </button>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
              {confirmBox && (
                <WithdrawalDetails
                  setConfirmBox={setConfirmBox}
                  currentItem={currentItem}
                  setIsLoading={setIsLoading}
                  isLoading={isLoading}
                  buttonsDisplay={"flex"}
                />
              )}
            </WithdrawalRequest>
          )}
          {menu2Active === 2 && (
            <WithdrawalRequest
              api="https://9jadelivery.com/femaletechMobile/public/api/withdrawalRequest"
              status="Paid"
              setApiData={setApiData}
              setIsLoading={setIsLoading}
              setErrorMessage={setErrorMessage}
            >
              <table>
                <thead>
                  <tr>
                    <th>S/N</th>
                    <th>Name</th>
                    <th></th>
                    <th colSpan="2">Amount</th>
                    <th></th>
                    <th colSpan="3">Date</th>
                    <th></th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {errorMessage && (
                    <ErrorMessage
                      message={`Error fetching program data : ${errorMessage}`}
                    />
                  )}
                  {isLoading ? (
                    <Loader />
                  ) : (
                    apiData?.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>

                        <td
                          style={{
                            textAlign: "center",
                          }}
                        >
                          <h3>{item.fullName}</h3>
                        </td>
                        <td></td>
                        <td
                          style={{
                            paddingLeft: "1rem",
                          }}
                        >
                          {item.currency}
                          {item.amount}
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>{item.date}</td>
                        <td></td>
                        <td></td>
                        <td>
                          <button
                            className="btn2Style"
                            onClick={() => {
                              setConfirmBox(true);
                              setCurrentItem(item);
                            }}
                            style={{
                              textTransform: "none",
                              fontWeight: "600",
                              fontFamily: "Lato, sans-serif",
                              fontSize: "1.2rem",
                              padding: "1rem 2rem",
                            }}
                          >
                            Bank Details
                          </button>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
              {confirmBox && (
                <WithdrawalDetails
                  setConfirmBox={setConfirmBox}
                  currentItem={currentItem}
                  setIsLoading={setIsLoading}
                  isLoading={isLoading}
                  buttonsDisplay={"none"}
                />
              )}
            </WithdrawalRequest>
          )}
        </AdminMenu2>
      )}
    </div>
  );
};

export default withAuth(AdminDashboard);
